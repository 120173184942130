import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { useHistory, useParams, Link } from "react-router-dom";
import orderReducer from "../store";
import Breadcrumb from "../../../layout/breadcrumb";
import withReducer from "../../../store/withReducer";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import moment from "moment";
import PreLoader from "../../../share-components/ui/preLoader";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { sellerAutoSuggestionApi } from "../../../services/manage-product/productService";
import { userAutoSuggestionApi } from "../../../services/manage-user/user";
import { DateRangePicker } from "rsuite";
import { alterationRequestFilters } from "../../manage-product/product/edit/content/settings/map";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

import {
  mapSearchAutoSuggestion,
} from "../../../utils/mapper";

import {
  getAllAlterationRequest,
  exportAlterationRequests,
  selectOrder,
  setAlterationRequestFilter,
} from "../store/orderSlice";


const AlterationRequest = (props) => {
  const { afterToday } = DateRangePicker;

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 50,
      border: "none",
    }),
  };

  const dispatch = useDispatch();
  let history = useHistory();

  const TodayDate = new Date();
  const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
  const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
  const [startDate, endDate] = dateRange;
  const [dateRangeRequested, setDateRangeRequested] = useState([sevenDays, TodayDate]);
  const [startDateRequested, endDateRequested] = dateRangeRequested;

  const orderState = useSelector(({ order }) => order.order);
  const userState = useSelector(({ authReducer }) => authReducer.data);
  const [searchSeller, setSearchSeller] = useState("");
  const [timer, setTimer] = useState(null);
  const orderAlterationRequest = useSelector(selectOrder);
  const submitTypes = ["submit", "update", "save"];
  const [differentPage, setDifferentPage] = useState(
    !submitTypes.includes(history.location.state?.from)
  );
  const [loading, isSetLoading] = useState(
    history.location.state?.from !== "update" || orderAlterationRequest.length < 1
  );
  const [isFilterable, setIsFilterable] = useState(
    history.location.state?.from !== "update" || orderAlterationRequest.length < 1
  );

  const dataMenu = [
    {
      type: 0,
      text: "Alteration Request",
    },
  ];

  const authData = useSelector(({ authReducer }) => authReducer);

  useEffect(() => {
    if (differentPage) {
      dispatch(
        setAlterationRequestFilter(alterationRequestFilters(authData.defaultSeller))
      );
    }
  }, []);

  useEffect(() => {
    if (
      !differentPage &&
      (isFilterable ||
        (isFilterable &&
          authData.defaultSeller?.value !=
          orderState.alterationFilters.seller?.value))
    ) {
      dispatch(
        setAlterationRequestFilter({
          ...orderState.alterationFilters,
          seller: authData.defaultSeller,
          currentPage: 1,
        })
      );
    }
  }, [authData.defaultSeller]);

  useEffect(() => {
    if (dateRange[0] !== null && dateRange[1] !== null) getData();
  }, [dateRange]);

  const getData = () => {
    if (isFilterable) {
      const alterationFilters = orderState.alterationFilters;
      if (
        differentPage &&
        JSON.stringify(alterationFilters) !=
        JSON.stringify(alterationRequestFilters(authData.defaultSeller))
      ) {
        setDifferentPage(false);
      } else {
        dispatch(
          getAllAlterationRequest({
            page: alterationFilters.currentPage,
            pageSize: alterationFilters.pageSize,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            requested_start_date: moment(startDateRequested).format('YYYY-MM-DD'),
            requested_end_date: moment(endDateRequested).format('YYYY-MM-DD'),
            seller: orderState.alterationFilters.seller ? orderState.alterationFilters.seller.value : "",
            status: orderState.alterationFilters.status ? orderState.alterationFilters.status.value : "",
            sku: orderState.alterationFilters.sku,
            orderNumber: orderState.alterationFilters.orderNumber,
          })
        ).then((res) => {
          isSetLoading(false);
        });
        setDifferentPage(false);
      }
    }
    setIsFilterable(true);
  };

  useEffect(() => {
    getData();
  }, [
    orderState.alterationFilters.currentPage,
    orderState.alterationFilters.pageSize,
    orderState.alterationFilters.orderNumber,
    orderState.alterationFilters.sku,
    orderState.alterationFilters.seller,
    orderState.alterationFilters.status,
    orderState.alterationFilters.updatedBy,
  ]);

  const sellerPromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)));
      });
    });

  const userPromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue
      }
      setTimeout(() => {
        resolve(mapSearchAutoSuggestion(userAutoSuggestionApi(data)))
      });
    });


  const tableColumns = [
    {
      name: "Order ID",
      selector: (row) => row.orderName,
      cell: (row) => (
        <Link
          className="text-primary"
          style={{ cursor: "pointer" }}
          target="_blank"
          to={`${process.env.PUBLIC_URL}/order/${row.order_id}/view`}
        >
          {row.orderName}
        </Link>
      ),
      width: "120px",
      sortable: true,
      center: false,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customer_name,
      width: "250px",
      sortable: true,
      center: false,
    },
    {
      name: "Requested Date",
      selector: (row) => row.requested_date,
      width: "200px",
      sortable: true,
      center: false,
    },
    {
      name: "Items",
      selector: (row) => row.item_labels,
      cell: (row) => (
        <div>
          {row.item_labels.map((lable, index) => {
            return <p className="d-block m-0" style={{ lineHeight: 1.5 }} key={'lable-' + index}>{lable}</p>;
          })}
        </div>
      ),
      sortable: true,
      center: false,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <div>
          <span className={`badge px-3 ${{
            'Pending': 'badge-primary',
            'Done': 'badge-success',
            'Partially Done': 'badge-warning'
          }[row.status]}`}>
            {row.status}
          </span>
        </div>
      ),
      width: "200px",
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row.id,
      cell: (row) =>
        <div>
          <a
            onClick={() => onView(row)}
            className={`btn btn-info btn-sm px-2 py-1 mr-2`}
          >
            <i className="fa fa-eye" />
          </a>
          {row.status != 'Done' && (
            <a
              onClick={() => onEdit(row)}
              className={`btn btn-primary btn-sm px-2 py-1`}
            >
              <i className="fa fa-reply" />
            </a>
          )}
        </div>
      ,
      width: "150px",
      sortable: false,
      right: true,
    },
  ];

  const pageChange = (e) => {
    dispatch(
      setAlterationRequestFilter({ ...orderState.alterationFilters, currentPage: e })
    );
  };

  const onPageSizeChange = (e) => {
    dispatch(
      setAlterationRequestFilter({
        ...orderState.alterationFilters,
        currentPage: 1,
        pageSize: e,
      })
    );
  };

  const onEdit = (row) => {
    history.push(`/order/alteration-requests/${row.id}/edit`);
  };

  const onView = (row) => {
    history.push(`/order/alteration-requests/${row.id}/view`);
  };

  const exportAlterationRequest = () => {
    isSetLoading(true);
    const data = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      seller: searchSeller?.value,
    };

    const outputFilename = `Alteration_Requests_${new Date()
      .toJSON()
      .slice(0, 10)}.csv`;

    dispatch(exportAlterationRequests({ ...data })).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      isSetLoading(false);
    });
  };

  const statusOptions = [
    { value: "1", label: "Pending" },
    { value: "2", label: "Processing" },
    { value: "3", label: "Completed" },
  ];

  return (
    <Fragment>
      {loading ? (
        <>
          <PreLoader></PreLoader>
        </>
      ) : (
        <>
          <Breadcrumb title="Alteration Requests" data={dataMenu} />
          <Container fluid={true} className="container-alteration">
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label className="font-weight-bold">Requested Date</Label>
                      <DatePicker
                        dateRange={dateRange}
                        dateRangeChange={(update) => {
                          setDateRangeRequested(update);
                        }}
                        disabledDate={afterToday()}
                        defaultValue={[TodayDate, sevenDays]}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <Label className="font-weight-bold">Ordered Date</Label>
                      <DatePicker
                        dateRange={dateRange}
                        dateRangeChange={(update) => {
                          setDateRange(update);
                        }}
                        disabledDate={afterToday()}
                        defaultValue={[TodayDate, sevenDays]}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="">
                    <div className='float-sm-right'>
                      <Button color="primary" className="btn btn-primary btn-square"
                        onClick={() => { exportAlterationRequest() }}
                      >Export</Button>
                    </div>
                  </Col>
                </Row>

                <Row>
                  {userState.user.role.name !== "Seller" && (
                    <Col sm="">
                      <AsyncSelect
                        className="select2-filter"
                        isClearable={true}
                        menuPortalTarget={document.body}
                        value={orderState.alterationFilters.seller}
                        defaultOptions
                        placeholder="Filter by Seller"
                        loadOptions={sellerPromiseOption}
                        styles={{
                          ...customStyles,
                          menuPortal: (base) => ({ ...base, zIndex: 100 }),
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          dispatch(
                            setAlterationRequestFilter({
                              ...orderState.alterationFilters,
                              seller: e ? e : null,
                              currentPage: 1,
                            })
                          );
                        }}
                      />
                    </Col>
                  )}

                  <Col sm="">
                    <FormGroup>
                      <Select
                        placeholder="Filter by Status"
                        className="select2-filter"
                        isClearable
                        cacheOptions
                        styles={customStyles}
                        options={statusOptions}
                        defaultValue={orderState.alterationFilters.status}
                        onChange={(e) => {
                          dispatch(
                            setAlterationRequestFilter({
                              ...orderState.alterationFilters,
                              status: e ? e : null,
                              currentPage: 1,
                            })
                          );
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="">
                    <Form onSubmit={(e) => { e.preventDefault(); }}>
                      <FormGroup className="m-0">
                        <Input
                          type="text"
                          className="form-control"
                          defaultValue={orderState.alterationFilters.sku}
                          onChange={(e) => {
                            clearTimeout(timer);
                            const newTimer = setTimeout(() => {
                              dispatch(
                                setAlterationRequestFilter({
                                  ...orderState.alterationFilters,
                                  sku: e.target.value,
                                  currentPage: 1,
                                })
                              );
                            }, 500);
                            setTimer(newTimer);
                          }}
                          placeholder="Filter by Sku"
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col sm="">
                    <Form onSubmit={(e) => { e.preventDefault(); }}>
                      <FormGroup className="m-0">
                        <Input
                          type="text"
                          className="form-control"
                          defaultValue={orderState.alterationFilters.orderNumber}
                          onChange={(e) => {
                            clearTimeout(timer);
                            const newTimer = setTimeout(() => {
                              dispatch(
                                setAlterationRequestFilter({
                                  ...orderState.alterationFilters,
                                  orderNumber: e.target.value,
                                  currentPage: 1,
                                })
                              );
                            }, 500);
                            setTimer(newTimer);
                          }}
                          placeholder="Filter by Order Number / MP Order Number"
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>

            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive product-table">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={orderState.alterationRequestLists}
                        paginationServer
                        noAction
                        paginationTotalRows={orderState.alterationRequestTableRow}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        onChangeRowsPerPage={onPageSizeChange}
                        onChangePage={pageChange}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Fragment>
  );
};

export default withReducer([{ order: orderReducer }])(AlterationRequest);
