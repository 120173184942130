import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import { Card, CardBody, Col, Container, Form, FormGroup, Nav, Row } from "reactstrap";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import PreLoader from "../../../../share-components/ui/preLoader";
import productReducer from "../../store";
import withReducer from "../../../../store/withReducer";
import {
    selectBinLocation, openNewDialog, closeNewDialog, createBinLocation,
    getAllBinLocation, openEditDialog, updateBinLocation, deleteBinLocation
} from "../../store/binLocationSlice";
import BinLocationForm from "./binLocationForm";
import { binLocationsResponseToTable } from "../../../../utils/mapper";

const defaultValues = {
    name: '',
    bin_location_type_id:'',
    capacity:''
};
const schema = yup.object().shape({
    name: yup.string().required('You must enter a bin location name'),
    bin_location_type_id:yup.string().required('You must select bin location type').typeError('Please select bin location type'),
    capacity: yup.number().transform(value => (isNaN(value) ? undefined : value)).required('You must enter a capacity'),
});

function BinLocation() {

    const dispatch = useDispatch();
    const [name, setName] = useState([]);
    const [loading, setLoading] = useState(true);
    const binLocations = useSelector(selectBinLocation);
    const [buttonDisable, setButtonDisable] = useState(false);
    const currentUser = useSelector(({ authReducer }) => authReducer.data.user);

    const dataMenu = [
        {
            type: 0,
            text: 'Products',
        },
        {
            type: 1,
            text: 'Product Setting',
            link: ''
        },
        {
            type: 2,
            text: 'Bin Location',
            link: ''
        }
    ];

    const tableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            left: true,
        },
        {
            name: 'Bin location type',
            selector: row => row.location_type,
            sortable: true,
            left: true,
        },
        {
            name: 'Capacity',
            selector: row => row.capacity,
            sortable: true,
            left: true,
        },

    ]

    const { handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { errors } = formState;

    useEffect(() => {
        const data = {
            name
        }

        dispatch(getAllBinLocation({ ...data })).then(res => {
            if (!res.error) setLoading(false);
        });

    }, [dispatch, name]);

    const closeDialog = () => {
        dispatch(closeNewDialog());
    };

    const onEdit = (row) => {
        dispatch(openEditDialog(row));
    };

    const onDelete = (row) => {
        dispatch(deleteBinLocation(row.id))
    }

    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        if (dialog.type === 'new') {
            dispatch(createBinLocation({ ...data })).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type)
            });
        } else {
            dispatch(updateBinLocation({ ...data })).then(res => {
                setButtonDisable(false);
                if (!res.error) closeDialog(dialog.type);
            });
        }
    };



    return (
        <Fragment>

            <Container fluid={true} className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></> : <>
                    <Breadcrumb data={dataMenu} title="Bin Location" />
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="12">

                                    <Card>
                                        <Row>
                                            <Col sm="6">
                                                <Nav tabs className="border-tab">

                                                </Nav>
                                            </Col>
                                            <Col sm="6">
                                                <div className='float-sm-right p-3'>
                                                    <a className="btn btn-sm btn-primary"
                                                        onClick={() => {
                                                            dispatch(openNewDialog())
                                                        }}
                                                    > <i className="fa fa-plus"></i> Add Bin Location</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>

                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <Form>
                                        <FormGroup className="m-0">

                                            <input onChange={(e) => {
                                                setName(e.target.value)
                                            }}
                                                type="text"
                                                className="form-control border"
                                                placeholder="Search by name" />
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive product-table mt-4">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={binLocationsResponseToTable(binLocations)}
                                            pagination
                                            // onEdit={onEdit}
                                            onEdit={(currentUser.permission).includes('Update Bin Locations') ? onEdit : ''}
                                        // onDelete={(currentUser.permission).includes('Delete Bin Locations') ? onDelete:''}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </>
                }

            </Container>

            <ModalFormSubmit
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                title='Bin Location'
                selector='product'
                dialog={({ product }) => product.binLocation.addDialog}
                closeDialog={closeDialog}
                buttonDisable={buttonDisable}
            >
                <BinLocationForm control={control} errors={errors} register={register} setValue={setValue}
                    trigger={trigger} getValues={getValues}
                />
            </ModalFormSubmit>
        </Fragment>
    )
}

// export default BinLocation
export default withReducer([{ BinLocation: productReducer }])(BinLocation);