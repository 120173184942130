import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {Badge, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Nav, Row} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";

import {productSearch, setProducts, setSearchProductDialog} from "../../../store/draftOrderSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import {ModalFooter} from "react-bootstrap";
import {openNewDialog} from "../../../../manage-product/store/prodcutSlice";
import PreLoader from "../../../../../share-components/ui/preLoader";
import moment from "moment";


const SearchProductModal = (props) => {
    const {setValue, trigger, getValues, imageSelectOnClick} = props;
    const dispatch = useDispatch();
    const orderState = useSelector(({order}) => order.draftOrder);
    let variantRef = useRef([]);
    let infiniteScrollRef = useRef();
    const [productData, setProductData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [checkedIds, setCheckedIds] = useState([]);

    const productSearchFunction = (isConcat, page) => {
        const parentValue = orderState.searchProductDialogProps.data.searchValue;
        if (!searchValue) {
            setSearchValue(orderState.searchProductDialogProps.data.searchValue);
        }
        if (searchValue || (!parentValue)) {
            dispatch(productSearch({name: searchValue, page: page})).then(res => {
                if (!res.error) {
                    dispatch(setProducts(res.payload))
                    if (isConcat) {
                        setProductData(prevArray => [...prevArray, ...res.payload.data])
                    } else {
                        setProductData(res.payload.data);
                    }
                }
            });
        }
    }

    const setSelectedProducts = () => {
        const selectedProducts = getValues('ordered_products_new');
        setValue('ordered_products', selectedProducts);
        trigger('ordered_products');
    }

    useEffect(() => {
        if (orderState.searchProductDialogProps.props.isOpen) {
            if (infiniteScrollRef.current) {
                infiniteScrollRef.current.actionTriggered = false;
            }

            productSearchFunction(false, 1);
        }
    }, [orderState.searchProductDialogProps.props, searchValue]);

    const closDialog = () => {
        setSearchValue("");
        dispatch(setSearchProductDialog({props: {isOpen: false}}))
    }

    const productOnChange = (e, product, variants) => {
        let orderProducts = getValues('ordered_products_new');
        variants.map((item, i) => {
            if (!item.is_out_of_stock && item.price > 0) {
                variantRef.current[item.id].checked = e.target.checked;
                orderProducts = productObjectUpdate(orderProducts, e.target.checked, product, item);
            }
        });

        setValue('ordered_products_new', orderProducts);
    }

    const variantOnChange = (e, product, variant) => {
        if(variant.price <= 0) {
            return;
        }
        let orderProducts = getValues('ordered_products_new');
        orderProducts = productObjectUpdate(orderProducts, e.target.checked, product, variant);
        setValue('ordered_products_new', orderProducts);
    }

    const productObjectUpdate = (orderProducts, isCheck, product, variant) => {
        let orderedProductIndex = orderProducts.findIndex(x => x.variant_id === variant.id);
        if (orderedProductIndex != '-1') {
            if (!isCheck) {
                orderProducts.splice(orderedProductIndex, 1);
            }
        } else {
            orderProducts.push({ product_id: product.id, variant_id: variant.id, product_name: product.name, image: product.url, price: variant.price, sku: variant.sku, variant_name: variant.variant_name, quantity: 1, total_price: variant.price, stock: variant.stock })
        }

        setCheckedIds((prevCheckedIds) => {
            if (prevCheckedIds.includes(variant.id)) {
                return prevCheckedIds.filter((checkedId) => checkedId !== variant.id);
            } else {
                return [...prevCheckedIds, variant.id];
            }
        });

        return orderProducts;
    }

    const fetchProductData = () => {        
        productSearchFunction(true, orderState.products.pagination.next_page);
    }

    const isCheckedProduct = (variants) => {
        let orderProducts = getValues('ordered_products_new') ?? [];
        let avaliableItemsCount = 0;
        let checkedItemsCount = 0;
        variants.map((item, i) => {
            if (!item.is_out_of_stock && item.price > 0) {
                avaliableItemsCount++;
                let orderedProductIndex = orderProducts.findIndex(x => x.variant_id === item.id);
                if (orderedProductIndex !== -1) {
                    checkedItemsCount++;
                }
            }
        });

        return avaliableItemsCount !==0 && avaliableItemsCount === checkedItemsCount;
    }

    const isCheckedItem = (item) => {
        let orderProducts = getValues('ordered_products_new') ?? [];
        let orderedProductIndex = orderProducts.findIndex(x => x.variant_id === item.id);
        return orderedProductIndex !== -1;
    }

    return (
        <Fragment>
            <div className="form-row mb-3">
                <Modal size="lg"    {...orderState.searchProductDialogProps.props} toggle={() => {
                    closDialog()
                }}>
                    <ModalHeader toggle={() => {
                        closDialog()
                    }}>
                        All Products
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-row mb-3">
                            <Col md="12 input-with-icon pr-1">
                                <div>
                                    <FormGroup className="m-0" >
                                        <i className="fa fa-search"></i>
                                        <input  name="code" type="text" placeholder="search Products" defaultValue={searchValue}
                                               onChange={(e) => {
                                                   setSearchValue(e.target.value?e.target.value:"");
                                               }}
                                               className={`form-control`}/>


                                    </FormGroup>
                                </div>
                            </Col>
                        </div>
                        <div className="form-row mb-3">

                            <InfiniteScroll
                                ref={(scroll) => {
                                    infiniteScrollRef.current = scroll;
                                }}
                                dataLength={productData.length}
                                next={fetchProductData}
                                hasMore={orderState.products.pagination.has_more}
                                refreshFunction={() => {
                                    alert("Test")
                                }}
                                pullDownToRefresh={true}
                                // onScroll={(e)=>{console.log(e)}}
                                height={600}
                                loader={<p>Load</p>}
                            >
                                <table className="table" style={{width: '100%', height: 400, overflowY: 'scroll'}}>

                                    <tbody>

                                    {productData.map((item, i) =>
                                        <Fragment key={i}>
                                            <tr>
                                                <td style={{width: '5%',height:1}}>
                                                    <input type={"checkbox"}
                                                        checked={isCheckedProduct(item.variants)}
                                                        onChange={(e) => {
                                                            productOnChange(e, item, item.variants);
                                                        }}
                                                    />
                                                </td>
                                                <td style={{width: '15%', textAlign: 'center'}}><img width={20}
                                                                                                     src={item.url}/>
                                                </td>
                                                <td style={{textAlign: 'left'}}>{item.name}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} style={{
                                                    paddingTop: 0,
                                                    paddingRight: 25,
                                                    paddingLeft: 25,
                                                    border: "none"
                                                }}>
                                                    <table className="table" style={{width: '100%',marginTop:0}}>
                                                        <tbody>
                                                        {item.variants.map((itemVariant, v) => <tr key={v}>
                                                            <td style={{width: '5%'}}>
                                                                <input ref={ref => { variantRef.current[itemVariant.id] = ref; }}
                                                                    disabled={itemVariant.is_out_of_stock || itemVariant.price <= 0} 
                                                                    checked={isCheckedItem(itemVariant)} 
                                                                    type={"checkbox"} 
                                                                    onChange={(e) => { variantOnChange(e, item, itemVariant); }}
                                                                />
                                                            </td>

                                                            <td style={{
                                                                    width: '50%',
                                                                    textAlign: 'left'
                                                                }}>
                                                                {itemVariant.variant_name}
                                                            </td>

                                                            <td style={{textAlign: 'right'}}>{itemVariant.format_stock}</td>
                                                            <td style={{textAlign: 'right'}}>£ {itemVariant.price}</td>
                                                        </tr>)}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                        </Fragment>
                                    )}


                                    </tbody>


                                </table>
                            </InfiniteScroll>

                        </div>

                    </ModalBody>

                    <ModalFooter>
                        <Row>
                            <Col sm="12">
                                <div className='float-sm-right '>
                                    <button onClick={() => {
                                        setSelectedProducts();
                                        closDialog();
                                    }} className="btn  btn-primary"> Add
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </ModalFooter>

                </Modal>
            </div>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.getValues) == JSON.stringify(nextProps.getValues);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(SearchProductModal, areEqual);