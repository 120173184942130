import React, {Fragment, useEffect, useState} from "react";
import {Row, Col, FormGroup, Label} from "reactstrap";
import {FormCheck} from "react-bootstrap";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import { getTagSettingData } from "../../store/productTagSettingSlice";
import Switch from "react-switch";


const BinLocationTypeForm = (props) => {
    const {
        errors,
        control,
        register,
        setValue,
        trigger,
        getValues,
        searchSellersByName,
        sellers,
        setOptions,
        promiseOptions
    } = props;
    const [settingData, setSettingData] = useState([]);
    const [isTag,setIsTag]=useState(getValues('is_tag'));
    const dispatch = useDispatch();


    return (
        <Fragment>
            
            <FormGroup>
                <Label className="col-form-label">Title</Label>
                <input name="title" type="text" {...register('title')} className={`form-control ${errors.title ? 'is-invalid' : ''}`}/>
                <div className="invalid-feedback">{errors.title?.message}</div>
            </FormGroup>
        
            <FormGroup>
                <Label className="col-form-label">Capacity</Label>
                <input name="capacity" type="number" {...register('capacity')} className={`form-control ${errors.capacity ? 'is-invalid' : ''}`}/>
                <div className="invalid-feedback">{errors.capacity?.message}</div>
            </FormGroup>
                
        </Fragment>
    );
};

export default BinLocationTypeForm;