import React from "react";
import { useDispatch } from "react-redux";
import { Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import DropZone from "../../../../share-components/upload/dropzone";
import Typography from "@material-ui/core/Typography";
import {baseUrlAsync} from "../../../../utils/api";


const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    }),
    menuPortal: base => ({ ...base, zIndex: 1000 })
};


export default function ImportRoyalmailTrackingDetailsForm(props) {

    const {
        dialog,
        size = 'lg',
        orderState,
        handleSubmit,
        onSubmitForm,
        handleFileInputChange,
        submitBtnSaveRef,
        importProductTrigger,
        setIsSeller,
        isSeller,
        setValue,
        buttonDisable,
        uploadFile,
        errors,
        productImportErrors,
        successProductImport,
        notImportedCount,
        closeDialog
    } = props;

    const dispatch = useDispatch();

    const closeImportProductTagDialog = () => {
        closeDialog();
    }

    return (
        <div className="flex flex-col modal-lg">
            <Modal size={size} {...orderState.importRoyalmailTrackingDialog.props} toggle={() => closeImportProductTagDialog()}>
                <ModalHeader toggle={() => {
                    closeImportProductTagDialog()
                }}>
                    Import Royalmail Tracking Details
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmitForm)}>
                        <Row className="mt-3 mb-3">
                            <Col md={12} className="mt-4">
                                <div className="product-box">
                                    <div className="product-img pt-3 pb-3">
                                        <DropZone
                                            extension={".csv, .xlsx, .xls"}
                                            onChangeFile={handleFileInputChange}></DropZone>
                                    </div>

                                    {uploadFile ? (
                                        <Typography>File: {`${uploadFile[0].name}`}{' '}</Typography>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </Col>
                            <Col md={9}></Col>
                            <Col md={3} className="mt-4">
                                <button disabled={buttonDisable} ref={submitBtnSaveRef} onClick={() => {
                                }} className="btn btn-success btn-block">
                                    {buttonDisable ? ( // Show the spinner if loading state is true
                                        <i className="fa fa-spinner fa-spin fa-lg spinner" />
                                    ) : (
                                        'Import' // Show the regular text if loading state is false
                                    )}
                                </button>
                            </Col>
                            <Col md={3}></Col>
                        </Row>

                        {(successProductImport) ?
                            <Row>
                                <Col md={12}>
                                    <h6 className="text-success">{successProductImport} tracking detail(s) imported successfully</h6>
                                </Col>
                            </Row>
                            : <></>
                        }

                        {(productImportErrors && productImportErrors.length > 0) ?
                            <Row>
                                <Col md={12}>
                                    <h6 className="text-danger">{notImportedCount} tracking detail(s) could not be imported due to following errors:</h6>
                                    <ul>
                                        {productImportErrors.map((error, index) => (
                                            <li key={index} className="text-danger">{error}</li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                            : <></>
                        }
                    </Form>

                    <ModalFooter className="mt-2">
                        <a href="#" style={{ color: "black", fontWeight: "normal" }}
                            onClick={(e) => {
                                const url = `${baseUrlAsync}/order/royalmail/import-tracking-details/sample-download`;
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', '');
                                document.body.appendChild(link);
                                link.click();
                                link.remove();
                            }}>
                            <i className="fa fa-download mr-1" aria-hidden="true"></i>
                            Download Sample CSV
                        </a>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    )
}