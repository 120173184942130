import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Row, Form, FormGroup, Input} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {useDispatch, useSelector} from "react-redux";
import sellerListData, {getAllSellerList, getSellerList} from "../store/sellerListSlice";
import {useHistory} from "react-router-dom";
import PreLoader from "../../../share-components/ui/preLoader";
import {
    getSellerSettings,
    setSettings
} from "../store/sellerSlice";
import Select from "react-select";

const dataMenu = [
    {
        type: 0,
        text: 'Seller',
        link: '/seller'
    },
    {
        type: 1,
        text: 'Seller List',
        link: ''
    },
];

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const SellerList = () =>{


    const tableColumns = [
        {
            name: 'Seller Name',
            selector: row => row.name,
            sortable: true,
            center: false,
        },
        {
            name: 'Shop Name',
            selector: row => row.shop_name,
            sortable: true,
            center: false,
        },
        {
            name: 'Publish',
            selector: row => row.published,
            sortable: true,
            center: false,
        },
        {
            name: 'Not Sent',
            selector: row => row.not_sent,
            sortable: true,
            center: false,
        },
        {
            name: 'Sent',
            selector: row => row.sent,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: (row) => <><a href="#" onClick={() => viewSeller(row)}><i className="fa fa-eye"
                                                                                style={{width: 25, fontSize: 16, padding: 11, color: 'rgb(80,126,83)'}}></i></a></>,
            sortable: true,
            center: false,
        },
    ];


    const sellerList = useSelector(getAllSellerList);
    const sellerListSlice = useSelector(({seller})=>seller.sellerListData);
    let history = useHistory();
    const dispatch = useDispatch();
    const [page,setPage]=useState(1);
    const [loading, isSetLoading] = useState(true);
    const [brand,setBrand]=useState(null);
    const [search,setSearch]=useState(null);
    const sellerState = useSelector(({ seller }) => seller.seller);

    useEffect(() => {
        dispatch(getSellerSettings()).then(res => {
            if (!res.error) {
                const results={
                    settings:res.payload,
                    id:null
                }
                dispatch(setSettings(results));
            }
        });
    },[dispatch]);

    useEffect(() => {
        getData();
    },[dispatch]);

    useEffect(() => {
        getData();
    }, [page,brand,search]);

    const getData = () =>{
        const data={
            page:page,
            page_size:10,
            brand:brand,
            search:search
        }
        dispatch(getSellerList(data)).then(res=>{
            if(!res.error)
                isSetLoading(false);
        });
    }


    const viewSeller = (row) => {
        history.push(`/seller/seller-product-list/${row.id}`)
    }



    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
            <>
                <Breadcrumb data={dataMenu} title="Seller List"/>
                <Container fluid={true}>
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm="6">
                                    <Select
                                        className="select2-filter border"
                                        isClearable
                                        cacheOptions
                                        styles={customStyles} placeholder="Select Brand" 
                                        getOptionLabel ={(option)=>option.name}
                                        getOptionValue ={(option)=>option.id} 
                                        onChange={(e)=>{
                                            setBrand(e?e.id:null);
                                            setPage(1);
                                        }} 
                                        options={sellerState.settings?sellerState.settings.brands:null} 
                                    />
                                </Col>
                                <Col sm="6">
                                    <Form>
                                        <FormGroup className="m-0">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                onChange={(e)=>{setSearch(e.target.value)}}
                                                placeholder="Search Seller Name/ Phone"
                                            />
                                            <i className="fa fa-search"></i>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                    <Col className="mt-4" sm="12">
                                        <Card>
                                            <CardBody>
                                                <div className="table-responsive product-table">
                                                    <CommonDataTable
                                                        noAction
                                                        headerColumns={tableColumns}
                                                        gridData={sellerList}
                                                        paginationTotalRows={sellerListSlice.sellerListDataTableRow}
                                                        paginationComponentOptions={{
                                                            noRowsPerPage: true
                                                        }}
                                                        paginationPerPage={10}
                                                        paginationServer
                                                        onChangePage={page => setPage(page)}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                            </Row>
                        </div>
                    </div>
                </Container>

            </>}
        </Fragment>
    );
}

export default SellerList;