import React, { Fragment } from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { FormCheck } from "react-bootstrap";
import Select from "react-select";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllBinLocationType } from "../../store/binLocationTypeSlice";
import { useState } from "react";

function BinLocationForm(props) {

    const {
        errors,
        control,
        register,
        setValue,
        trigger,
        getValues,
        searchSellersByName,
        sellers,
        setOptions,
        promiseOptions
    } = props;
    const dispatch = useDispatch();
    const [capacityValue, setCapacityValue] = useState(0);
    const [binLocationTypeData, setBinLocationTypeData] = useState([]);
    useEffect(() => {
        dispatch(getAllBinLocationType()).then(res=>{
            if (!res.error)
            setBinLocationTypeData(res.payload);
        });

    }, [dispatch]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleCapacity = (event)=>{
        setValue('capacity',event?event.capacity:0);
        setCapacityValue(event.capacity);
    };
    console.log(getValues('bin_location_type_id'));
    return (
        <Fragment>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label"> Bin Name</Label>
                        <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.name?.message}</div>
                    </FormGroup>

                    <FormGroup>
                        <Label className="col-form-label">Bin Location Type</Label>
                        <Select getOptionLabel={(option) => option.title}
                                // menuPortalTarget={document.body}
                                styles={{menuPortal: base => ({...base, zIndex: 100})}}
                                defaultValue={getValues('bin_location_type_id')}
                                getOptionValue={(option) => option.id} {...register("bin_location_type_id")}
                                onChange={(e) => {
                                    handleCapacity(e);
                                    setValue('bin_location_type_id', e.id);
                                    trigger('bin_location_type_id');
                                    // setCapacityValue(e.capacity);
                                }} className={` ${errors.bin_location_type_id ? 'is-invalid' : ''}`}
                                options={binLocationTypeData}/>

                        <div className="invalid-feedback">{errors.bin_location_type_id?.message}</div>
                    </FormGroup>

                    <FormGroup>
                        <Label className="col-form-label">Capacity</Label>
                        <input name="capacity" defaultValue={capacityValue} type="text" {...register('capacity')}
                         className={`form-control ${errors.capacity ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.capacity?.message}</div>
                    </FormGroup>
                </Col>
            </Row>

        </Fragment>
    )
}

export default BinLocationForm;