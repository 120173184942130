import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { DateRangePicker } from 'rsuite';

import {
    Card, CardBody, Col, Container,
    Form, FormGroup, Input, Row
} from "reactstrap";

import Breadcrumb from "../../../../layout/breadcrumb";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import { mapSearchAutoSuggestion } from "../../../../utils/mapper";
import withReducer from "../../../../store/withReducer";

import sellerReducer from "../../store";
import {
    getSellerSettings,
    setSettings, setTableDataCommission
} from "../../store/sellerSlice";
import PreLoader from "../../../../share-components/ui/preLoader";
import { getDetailCommissions } from "../../store/sellerCommissionSlice";
import { sellerAutoSuggestionApi } from "../../../../services/manage-product/productService";
import DatePicker from "../../../../share-components/ui/DateRangePicker";
import { formatterDecimal } from "../../../../utils/common";
const { afterToday } = DateRangePicker;

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const DetailCommission = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const seller_id = queryParams.get("seller");
    const seller_name = queryParams.get("seller_name");
    const start_date = queryParams.get("start_date");
    const end_date = queryParams.get("end_date");

    const selectedSellerOption = {
        label: seller_name,
        value: seller_id
    };

    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const TodayDate = end_date ? Date.parse(end_date) : new Date();
    const sevenDays = start_date ? Date.parse(start_date) : new Date().setDate(TodayDate.getDate() - 7);
    const sellerState = useSelector(({ seller }) => seller.seller);
    const sellerCommission = useSelector(({ seller }) => seller.sellerCommission);
    const userState = useSelector(({ authReducer }) => authReducer.data);
    const [search, setSearch] = useState(null);
    const [order, setOrder] = useState(null);
    const [loading, isSetLoading] = useState(false);
    const [tableLoading, isSetTableLoading] = useState(false);
    const [seller, setSeller] = useState(seller_id ? selectedSellerOption : null);
    const [agent, setAgent] = useState(null);

    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);

    const [startDate, endDate] = dateRange;

    const dataMenu = [
        {
            type: 0,
            text: "Detail Commission"
        }
    ];

    const tableColumns = [
        {
            name: '#',
            selector: row => row.order_name,
            sortable: true,
            center: false,
            width: "100px"
        }, {
            name: 'Date',
            selector: row => row.order_date,
            sortable: true,
            center: false,
        },
        {
            name: 'SKU',
            selector: row => row.sku,
            sortable: true,
            center: false,
        },
        {
            name: 'Product',
            selector: row => row.product_name,
            sortable: true,
            center: false,
        },
        {
            name: 'Qty',
            selector: row => row.quantity,
            sortable: true,
            center: true,
            width: "100px"
        },
        {
            name: 'Price (£)',
            selector: row => formatterDecimal.format(row.total_price),
            sortable: true,
            right: false,
        },
        {
            name: 'Price Excl Vat (£)',
            selector: row => formatterDecimal.format(row.total_price_exc_vat),
            sortable: true,
            right: false,
        },
        {
            name: 'Admin Percentage',
            selector: row => formatterDecimal.format(row.commission_percentage) + "%",
            sortable: true,
            center: false,
        }, {
            name: 'Admin Commission (£)',
            selector: row => formatterDecimal.format(row.admin_commission),
            sortable: true,
            right: false,
        }
    ];

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

    useEffect(() => {
        const timer = setTimeout(() => {
            setOrder(search);
        }, 1500);

        return () => clearTimeout(timer);

    }, [search])

    useEffect(() => {
        dispatch(getSellerSettings()).then(res => {
            if (!res.error) {
                const results = {
                    settings: res.payload,
                    id: null
                }
                dispatch(setSettings(results));
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getData();
    }, [page, startDate, endDate, order, seller, agent]);

    const getData = () => {
        const data = {
            page: page,
            pageSize: 10,
            order: order,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            // seller_id: seller,
            seller_id: seller && seller.value ? seller.value : '',
            agent_id: agent
        }

        isSetTableLoading(true);

        dispatch(getDetailCommissions(data)).then(res => {
            if (!res.error) {
                isSetLoading(false);
            }
            isSetTableLoading(false);
        });
    }

    return (
        <Fragment>
            {loading ? <>
                <PreLoader></PreLoader>
            </> : <>
                <Breadcrumb title="Detail Commission" data={dataMenu} />
                <Container fluid={true} className="product-wrapper">
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm={userState.user.role.name === "Seller" ? 4 : 3}>
                                    <div className="dashboard-datepicker">
                                        <DatePicker
                                            dateRange={dateRange}
                                            dateRangeChange={(date) => {
                                                setDateRange(date)
                                            }}
                                            defaultValue={[TodayDate, sevenDays]}
                                            disabledDate={afterToday()}
                                        />
                                    </div>
                                </Col>

                                <Col sm={userState.user.role.name === "Seller" ? 4 : 3}>
                                    <Form>
                                        <FormGroup className="m-0">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => { setSearch(e.target.value) }}
                                                placeholder="Order Number"
                                            />
                                            <i className={tableLoading ? "fa fa-spinner fa-spin" : "fa fa-search"}></i>

                                        </FormGroup>
                                    </Form>
                                </Col>

                                {userState.user.role.name !== "Seller" && (
                                    <Col sm="3">
                                        <AsyncSelect
                                            className="select2-filter"
                                            isClearable={true}
                                            menuPortalTarget={document.body}
                                            defaultOptions
                                            styles={{ ...customStyles, menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                            placeholder="Select Seller"
                                            loadOptions={sellerPromiseOption}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            onClear={(e) => {

                                            }}
                                            value={seller}
                                            onChange={(e) => {
                                                setSeller(e);
                                            }}
                                        />
                                    </Col>
                                )}

                                {((userState.user.role.name !== "Seller") || (userState.user.is_primary_seller)) && (
                                    <Col sm="3">
                                        <Select
                                            placeholder="Agent/Sales Person"
                                            className="select2-filter"
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isClearable
                                            cacheOptions
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            styles={customStyles}
                                            options={sellerState.settings.agents}
                                            onChange={(e) => {
                                                setAgent(e ? e.id : null)
                                            }}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </div>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={sellerCommission.detailCommissionLists}
                                            noAction
                                            progressPending={tableLoading}
                                            paginationServer
                                            paginationTotalRows={sellerCommission.detailCommissionTableRow}
                                            paginationPerPage={10}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={page => setPage(page)}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>}
        </Fragment>
    );
}

export default withReducer([{ seller: sellerReducer }])(DetailCommission);