import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, Link } from "react-router-dom";

import Breadcrumb from "../../../../layout/breadcrumb";
import orderReducer from "../../store";
import PreLoader from "../../../../share-components/ui/preLoader";
import withReducer from "../../../../store/withReducer";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import LogActivity from "../../../log";


import {
    showAlterationRequest
} from "../../store/orderSlice";

const ViewAlterationRequest = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const orderState = useSelector(({ order }) => order.order);
    const [loading, isSetLoading] = useState(true);
    const authData = useSelector(({ authReducer }) => authReducer);
    const [tableLoading, isSetTableLoading] = useState(false);

    const dataMenu = [
        {
            type: 1,
            text: 'Alteration Requests',
            link: '/order/alteration-requests'
        },
        {
            type: 0,
            text: "View"
        }
    ];

    useEffect(() => {
        const requestParmas = {
            params: {
                seller: authData.defaultSeller?.value
            },
            id: id
        }
        isSetTableLoading(true);
        dispatch(showAlterationRequest(requestParmas)).then(res => {
            if (!res.error) {
                isSetLoading(false);
            }
            isSetTableLoading(false);
        });
    }, [dispatch, authData.defaultSeller]);


    let tableColumns = [
        {
            name: 'Product',
            selector: row => row.product_name,
            cell: (row) => (
                <div style={{ width: '100%', padding: '10px 0' }}>
                    <div style={{ width: '20%', float: 'left', paddingRight: '10px', textAlign: 'center' }}>
                        <img className="img-fluid" src={row.image} width="40" alt="" />
                    </div>
                    <div style={{ width: '80%', float: 'left' }}>
                        <p className={'font-primary'}>{row.product_name}</p>
                        <p style={{ lineHeight: 1.2, marginTop: "5px" }}>{row.colour}/{row.size}</p>
                        <p style={{ lineHeight: 1.2, marginTop: "5px" }}>{row.sku}</p>
                        <p style={{ lineHeight: 1.2, marginTop: "5px" }}>{row.brand_name}</p>
                    </div>
                </div>
            ),
            width: "40%",
            sortable: false,
            center: false,
        },
        {
            name: 'SKU',
            selector: row => row.sku,
            sortable: false,
            center: false,
        },
        {
            name: 'Ordered Qty',
            selector: row => row.ordered_quantity,
            width: "10%",
            sortable: false,
            center: false,
        },
        {
            name: 'Requested Qty',
            selector: row => row.request_quantity,
            width: "10%",
            sortable: false,
            center: false,
        },
        {
            name: 'Status',
            selector: row => row.status_id,
            cell: (row) => (
                <div>
                    <span className={`badge px-3 ${{
                        1: 'badge-primary',
                        2: 'badge-warning',
                        3: 'badge-success',
                    }[row.status_id] || 'badge-danger'}`}>
                        {row.status}
                    </span>
                </div>
            ),
            width: "200px",
            sortable: false,
            center: true,
        },

    ];

    const onEdit = (row) => {
        history.push(`/order/alteration-requests/${row.id}/edit`);
    };

    // Tracking   
    let trackingTableColumns = [
        {
            name: 'ID',
            selector: row => row.id,
            width: "100px",
            sortable: false,
            center: false,
        },
        {
            name: 'Tracking Name',
            selector: row => row.tracking_name,
            sortable: false,
            center: false,
        },
        {
            name: 'Tracking ID',
            selector: row => row.tracking_id,
            sortable: false,
            center: false,
        },
        {
            name: 'Tracking URL',
            selector: row => row.tracking_url,
            sortable: false,
            center: false,
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: false,
            center: false,
        },
        {
            name: 'Updated At',
            selector: row => row.updated_at,
            sortable: false,
            center: false,
        },
    ];

    // data provides access to your row data
    const expandedChildItems = ({ data }) => {
        const childTableColumns = [
            {
                name: 'Product',
                selector: row => row.product_name,
                cell: (row) => (
                    <div style={{ width: '100%', padding: '10px 0' }}>
                        <div style={{ width: '20%', float: 'left', paddingRight: '10px', textAlign: 'center' }}>
                            <img className="img-fluid" src={row.image} width="40" alt="" />
                        </div>
                        <div style={{ width: '80%', float: 'left' }}>
                            <p className={'font-primary'}>{row.product_name}</p>
                            <p style={{ lineHeight: 1.2, marginTop: "5px" }}>{row.colour}/{row.size}</p>
                            <p style={{ lineHeight: 1.2, marginTop: "5px" }}>{row.sku}</p>
                            <p style={{ lineHeight: 1.2, marginTop: "5px" }}>{row.brand_name}</p>
                        </div>
                    </div>
                ),
                width: "40%",
                sortable: false,
                center: false,
            },
            {
                name: 'SKU',
                selector: row => row.sku,
                sortable: false,
                center: false,
            },
            {
                name: 'Ordered Qty',
                selector: row => row.ordered_quantity,
                width: "10%",
                sortable: false,
                center: false,
            },
            {
                name: 'Requested Qty',
                selector: row => row.request_quantity,
                width: "10%",
                sortable: false,
                center: false,
            },
            {
                name: 'Status',
                selector: row => row.status_id,
                cell: (row) => (
                    <div>
                        <span className={`badge px-3 ${{
                            1: 'badge-secondary',
                            2: 'badge-primary',
                            3: 'badge-success',
                        }[row.status_id] || 'badge-danger'}`}>
                            {row.status}
                        </span>
                    </div>
                ),
                width: "200px",
                sortable: false,
                center: true,
            },
        ];

        const childData = data.items;

        return (
            <div className="px-5 pt-3 pb-5 border-bottom">
                <CommonDataTable
                    className="refunded-histories-child-table"
                    headerColumns={childTableColumns}
                    data={childData}
                    noAction
                    pagination={false}
                />
            </div>
        );
    };

    return (
        <Fragment>
            {loading ? <>
                <PreLoader></PreLoader>
            </> : <>
                <Breadcrumb title="View Alteration Request" data={dataMenu} />
                <Container fluid={true}>

                    <div style={{position: 'fixed', top: '50%', zIndex: 10,right:0}}>
                        <LogActivity log="Alteration Request" id={id}></LogActivity>
                    </div>

                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="">
                                            <h5 className="mb-2">Details of Alteration Request</h5>
                                        </Col>
                                        <Col md="1">
                                            {orderState.alterationRequestData.data.status != 'Done' && (
                                                <a
                                                    onClick={() => onEdit(orderState.alterationRequestData.data)}
                                                    className={`btn btn-primary btn-sm px-2 py-1 float-right`}
                                                >
                                                    <i className="fa fa-reply"></i> Open Edit
                                                </a>
                                            )}
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col>
                                            <p><b>Requested Date:</b> {orderState.alterationRequestData.data.requested_date}</p>
                                            <p>
                                                <b>Status:</b>
                                                <span className={`badge px-2 ml-2 ${{
                                                    'Pending': 'badge-primary',
                                                    'Done': 'badge-success',
                                                    'Partially Done': 'badge-warning'
                                                }[orderState.alterationRequestData.data.status]}`}>
                                                    {orderState.alterationRequestData.data.status}
                                                </span>
                                            </p>
                                            <p>
                                                <b>Order No:</b>
                                                <Link
                                                    className="text-primary ml-2"
                                                    style={{ cursor: "pointer" }}
                                                    target="_blank"
                                                    to={`${process.env.PUBLIC_URL}/order/${orderState.alterationRequestData.data.order_id}/view`}
                                                >
                                                    {orderState.alterationRequestData.data.order_name}
                                                </Link>
                                            </p>
                                            <p><b>Customer:</b> {orderState.alterationRequestData.data.customer_name}</p>
                                        </Col>
                                    </Row>

                                    <h5 className="mb-2 mt-5">List of Requested Items</h5>

                                    <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            progressPending={tableLoading}
                                            data={orderState.alterationRequestData.data.items}
                                            noAction
                                            pagination={false}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <h5 className="mb-2">List of Trackings</h5>

                                    <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={trackingTableColumns}
                                            progressPending={tableLoading}
                                            data={orderState.alterationRequestData.data.trackings}
                                            noAction
                                            pagination={false}
                                            expandableRows
                                            expandableRowsComponent={expandedChildItems}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>}
        </Fragment>
    );
}

export default withReducer([{ order: orderReducer }])(ViewAlterationRequest);