import React, { Fragment } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from "reactstrap";

export const PrimarySection = (props) => {
    const { saleOffer } = props;

    const getStatusBadgeVariant = (status) => {
        switch (status) {
            case 'Active':
                return 'success';
            case 'Expired':
                return 'danger';
            case 'Scheduled':
                return 'warning';
            default:
                return '';
        }
    };

    return (
        <Fragment>
            <Card className="ribbon-wrapper">
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-primary">Primary Details</div>

                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label className="form-label font-weight-bold">Title</Label>
                                <p>{saleOffer.sale_title}</p>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label className="form-label font-weight-bold">Discount Type</Label>
                                <p>{saleOffer.discount_type}</p>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label className="form-label font-weight-bold">Discount</Label>
                                <p>{saleOffer.discount} {(saleOffer.discount_type == "Percentage") ? "%" : ""}</p>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup >
                                <Label className="form-label font-weight-bold">Status</Label>
                                <br />
                                <Badge color={getStatusBadgeVariant(saleOffer.status_format)}>
                                    {saleOffer.status_format}
                                </Badge>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label className="form-label font-weight-bold">Start Date</Label>
                                <p>{saleOffer.start_date}</p>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label className="form-label font-weight-bold">End Date</Label>
                                <p>{saleOffer.end_date}</p>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label className="form-label font-weight-bold">Created At</Label>
                                <p>{saleOffer.created_at_format}</p>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label className="form-label font-weight-bold">Created By</Label>
                                <p>{saleOffer.created_by_name}</p>
                            </FormGroup>
                        </Col>
                        
                        <Col md={12}>
                            <Label className="col-form-label">Tags</Label>
                            <div>
                                {saleOffer.tags.map((item, i) =>
                                    <Badge color="primary" key={i}>{item}</Badge>
                                )}

                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    );
}
