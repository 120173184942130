import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {Card, CardBody, Col, Container, Form, FormGroup, Label, Row} from "reactstrap";
import PreLoader from "../../../share-components/ui/preLoader";
import Breadcrumb from "../../../layout/breadcrumb";
import {useDispatch} from "react-redux";
import {updateDispatchOrderData} from "../store/orderSlice";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import useSound from "use-sound";
import alertSound from '../../../assets/audio/alert.mp3';

const defaultValues = {
    id: '',
};

const dataMenu = [
    {
        type: 0,
        text: 'Order',
        link: '/order'
    },
    {
        type: 1,
        text: 'Item Dispatch',
        link: ''
    },
];

export const schema = yup.object().shape({});

const DispatchOrder = () => {

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(false);
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const [playAlertSound] = useSound(alertSound);

    const {handleSubmit, formState, reset, register, trigger, setValue, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const submitBtnSaveRef = useRef();

    const {errors} = formState;

    const onSubmitForm = (data) => {
        const finalData = {
            id: data.id
        }
        // This is a fraudulent order, do not process this order. Please contact system administrator support.
        // Tracking details not updated.
        // Please contact system administrator support.
        // Not found!
        // Already order dispatched.
        // The order pack is not complete.
        // The id field is required.
        dispatch(updateDispatchOrderData({ ...finalData })).then(res => {
            console.log('RESPONSE', res);
            if (!res.error) {
                submitBtnSaveRef.current.focus();
            }else if(res.error.message == "This is a fraudulent order, do not process this order. Please contact system administrator support." || res.error.message == "Tracking details not updated." || res.error.message == "Please contact system administrator support." || res.error.message == "Not found!" || res.error.message == "Already order dispatched." || res.error.message == "The order pack is not complete." || res.error.message == "The id field is required."){
                playAlertSound();
            }
            setValue('id', '');
            trigger('id');
        });
    }

    const handleChange = (event) => {
        setValue('id',event.target.value);
        trigger('id')
    };
    
    const TodayDate = new Date();
    const lastFiveDays = new Date().setDate(TodayDate.getDate() - 5);

    return (
        <Fragment>
            <Container fluid className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></> : <>
                    <Breadcrumb data={dataMenu} title="Item Dispatch"/>
                    <div className="product-grid">
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmit(onSubmitForm)}>
                                    <Row>
                                        <Col md={3} sm={4} xs={12}>
                                            {/*<Label className="form-label d-none d-md-block">Order Number / Invoice ID</Label>*/}
                                        </Col>
                                        <Col md={6} sm={4} xs={12} className="mb-3 mb-sm-0">
                                            <FormGroup className="m-checkbox-inline mb-0">
                                                <input
                                                    ref={(ref)=>{
                                                        submitBtnSaveRef.current=ref;
                                                    }}
                                                    name="id"
                                                    type="text"
                                                    value={getValues('id')}
                                                    autoFocus
                                                    className={`form-control ${errors.id ? 'is-invalid' : ''}`}
                                                    placeholder="Order Name / Tracking ID"
                                                    onChange={handleChange}
                                                />
                                                <div className="invalid-feedback">{errors.id?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} sm={4} xs={12} className="mb-3 mb-sm-0">
                                            <button
                                                style={{}}

                                                onClick={() => {
                                                    // setActionBtn(1)
                                                }}
                                                className="btn btn-success btn-block"
                                            >
                                                Dispatch
                                            </button>
                                        </Col>
                                        <Col md={1} xs={12}></Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12} sm={12} xs={12}>
                                        <a className="btn btn-success mr-3 text-decoration-none" href={`${process.env.PUBLIC_URL}/order/?start_date=${moment(lastFiveDays).format('YYYY-MM-DD')}&end_date=${moment(TodayDate).format('YYYY-MM-DD')}&status=fulfilled&financialStatus=paid`} target="_blank"><i className="fa fa-truck"></i> Fulfilled</a>
                                        <a className="btn text-decoration-none" target="_blank" style={{backgroundColor: 'rgb(68, 51, 255)', color: 'white'}} 
                                        href={`${process.env.PUBLIC_URL}/order/?status=unfulfilled&financialStatus=paid`}><i className="fa fa-truck"></i> Unfulfilled</a>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </>}
            </Container>
        </Fragment>
    );
}

export default (DispatchOrder)