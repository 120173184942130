import React, { Fragment, useEffect, useRef, useState } from "react";

import {
    Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

import Image from "./image";
import Select from "react-select";

const ItemCard = (props) => {
    const { setValue, trigger, getValues, register, errors, collectionId, item, itemIndex, removeItem, updateItem } = props;

    const [dropdownOpenAction, setDropdownOpenAction] = useState(false);
    const [dropdownOpenStatus, setDropdownOpenStatus] = useState(false);

    const toggleDropdownAction = () => setDropdownOpenAction(prevState => !prevState);
    const toggleDropdownStatus = () => setDropdownOpenStatus(prevState => !prevState);

    const statusLists = [
        {value: 1, title: 'Active'},
        {value: 0, title: 'Deactive'}
    ];

    const findStatusByValue = (value) => {
        return statusLists.find(status => status.value === value);
    }

    const isErrorItemInput = (itemIndex, name) => !!errors.items?.[itemIndex]?.[name];
    const errorMessageItemInput = (itemIndex, name) => errors.items?.[itemIndex]?.[name] || false;

    return (
        <Fragment>
            <Card>
                <div style={{ width: '300px', position: 'absolute', top: '20px', right: '20px', textAlign: 'right' }}>
                    {/* <Dropdown isOpen={dropdownOpenStatus} toggle={toggleDropdownStatus} className="d-inline-block ml-2">
                        <DropdownToggle caret color="success">
                            Active
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem>Active</DropdownItem>
                            <DropdownItem>Deactive</DropdownItem>
                        </DropdownMenu>
                    </Dropdown> */}

                    <Dropdown isOpen={dropdownOpenAction} toggle={toggleDropdownAction} className="d-inline-block ml-2">
                        <DropdownToggle caret color="primary">
                            Actions
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem className="text-danger" onClick={() => {
                                removeItem(itemIndex);
                            }}>Delete</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <CardBody>
                    <Row>
                        <Col md="5">
                            <FormGroup>
                                <Label for="">Title</Label>
                                <Input
                                    type="text"
                                    name={`items[${itemIndex}].name`}
                                    // {...register(`items[${itemIndex}].name`)}
                                    // ref={register}
                                    onChange={(e) => {
                                        console.log("Name", e.target.value);
                                        setValue(`items[${itemIndex}].name`, e.target.value);
                                        trigger(`items[${itemIndex}].name`);
                                    }}
                                    defaultValue={item.name}
                                    className={` ${isErrorItemInput(itemIndex, 'name') ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errorMessageItemInput(itemIndex, 'name')?.message}</div>
                            </FormGroup>
                        </Col>

                        <Col md="5">
                            <FormGroup>
                                <Label for="">Status</Label>
                                <Select
                                    placeholder="Select Color"
                                    type="select"
                                    className={` ${isErrorItemInput(itemIndex, 'status') ? 'is-invalid' : ''}`}
                                    name={`items[${itemIndex}].name`}
                                    getOptionLabel={(option) => option.title}
                                    getOptionValue={(option) => option.value}
                                    isClearable
                                    defaultValue={findStatusByValue(item.status)}
                                    options={statusLists}
                                    onChange={(e) => {
                                        setValue(`items[${itemIndex}].status`, e.value);
                                        trigger(`items[${itemIndex}].status`);
                                    }}

                                />
                                <div className="invalid-feedback">{errorMessageItemInput(itemIndex, 'status')?.message}</div>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <Image 
                                setValue={setValue} 
                                getValues={getValues} 
                                trigger={trigger} 
                                collectionId={collectionId} 
                                item={item} 
                                itemIndex={itemIndex} 
                                images={item.images}
                                updateItem={updateItem}
                            ></Image>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default ItemCard;