import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { getAllRestockReportApi, generateReturnRestockReport } from "../../../services/order";
import { sellerListSlice } from "../../seller/store/sellerListSlice";
import {
  reportFilters,
  RestockReportFilters,
  pendingReturnFilters,
} from "../../manage-product/product/edit/content/settings/map";

export const getReturnRestockListReport = createAsyncThunk(
  "orders/getReturnRestockReport",
  async (data, { dispatch }) => {
    const response = await getAllRestockReportApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
  }
);

export const exportReturnRestockReport = createAsyncThunk('order/exportReturnRestockReport', async (data, {dispatch}) => {
  const response = await generateReturnRestockReport(data);
  return response.response;
});


export const restockReportAdapter = createEntityAdapter({});

export const {
  selectAll: selectReturnRestockReport,
  selectById: getReturnRestockReportbyId,
} = restockReportAdapter.getSelectors(
  (state) => state.order.returnRestockListReport
);

export const restockReportSlice = createSlice({
  name: "returnRestockListReport",
  initialState: restockReportAdapter.getInitialState({
    id: null,
    restockOrdersDataTableRow: 0,
    searchText: "",
    filter: reportFilters(),
    filters: RestockReportFilters(),
    returnData: null,
  }),
  reducers: {
    setTableRowCount: (state, action) => {
      state.restockOrdersDataTableRow = action.payload;
    },
    setRestockReportFilter: (state, action) => {
      state.filters = action.payload;
    },
    setReturnRestockData: (state, action) => {
      state.returnData = action.payload;
    },
    setRestockedByFilter: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: {
    [getReturnRestockListReport.fulfilled]: restockReportAdapter.setAll,
  },
});

export const {
  setTableRowCount,
  setRestockReportFilter,
  setReturnRestockData,
  setRestockedByFilter,
} = restockReportSlice.actions;

export default restockReportSlice.reducer;
