
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";
import PreLoader from "../../../share-components/ui/preLoader";
import {
    getAllCollections,
} from "../store/collectionSlice";

import withReducer from "../../../store/withReducer";
import productReducer from "../store/index";

import CommonDataTable from "../../../share-components/table/commonDataTable";
import { mapCollectionResponseToTable } from "../../../utils/mapper";

const Collection = () => {
    const dispatch = useDispatch();
    let history = useHistory();

    const collectionState = useSelector(({ product }) => product.collection);

    const [loading, setLoading] = useState(false);
    const [collectionsItems, setCollectionsItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newPageSize) => {
        setPageSize(newPageSize);
        setCurrentPage(1); // Reset to first page
    };

    const getAllCollectionItems = () => {
        dispatch(
            getAllCollections({
                pageSize: pageSize,
                page: currentPage,
            })
        ).then((res) => {
            if (!res.error) {
                setLoading(false);
                setCollectionsItems(mapCollectionResponseToTable(res.payload));
            }
        });
    };

    const tableColumns = [
        {
            name: "Title",
            selector: (row) => row.title,
            sortable: false,
            center: false,
        },
        {
            name: "Handle",
            selector: (row) => row.handle,
            sortable: false,
            center: false,
        },
        {
            name: "Status",
            selector: (row) => {
                if (row.status == 1) {
                    return <span className="badge badge-success">Active</span>
                }

                return <span className="badge badge-warning">Deactive</span>
            },
            sortable: false,
            center: true,
        },
        // {
        //     name: "Actions",
        //     selector: (row) => row.seller,
        //     sortable: false,
        //     right: true,
        // },
    ];

    useEffect(() => {
        getAllCollectionItems();
    }, [dispatch, currentPage, pageSize])

    const onEdit = (row) => {
        history.push("/product/collections/" + row.id + "/edit");
    };

    const dataMenu = [
        {
            type: 0,
            text: "Collections",
        },
    ];

    return (
        <Fragment>
            {loading ? <>
                <PreLoader></PreLoader>
            </> : <>
                <Breadcrumb title="Collections" data={dataMenu} />
                <Container fluid={true}>
                    <Card>
                        <CardBody>
                            <div className="table-responsive collection-table">
                                <CommonDataTable
                                    headerColumns={tableColumns}
                                    data={collectionsItems}
                                    onEdit={onEdit}
                                    paginationServer
                                    paginationTotalRows={collectionState.tableRow}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handleRowsPerPageChange}
                                    paginationDefaultPage={currentPage}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </>}
        </Fragment>
    );
}

export default withReducer([{ product: productReducer }])(Collection);