import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { Badge, Card, CardBody, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import { useParams } from "react-router-dom";

import withReducer from "../../../../store/withReducer";
import productReducer from "../../store";
import Breadcrumb from "../../../../layout/breadcrumb";
import PreLoader from "../../../../share-components/ui/preLoader";
import { showSaleOffer } from "../../store/saleOfferSlice";
import { setProductPagination, setProductFilter, getProducts } from "../../store/prodcutSlice";

import { ProductsSection } from "./sections/products";
import { PrimarySection } from "./sections/primary";


function ViewSaleOffer() {
    const [loading, setLoading] = useState(true);
    const [saleOffer, setSaleOffer] = useState(null);
    const [products, setProducts] = useState(null);
    const [isGetProduct, setIsGetProduct] = useState(false);
    const dispatch = useDispatch();
    const productState = useSelector(({ product }) => product.product);

    const { id } = useParams();

    const dataMenu = [
        {
            type: 1,
            text: 'Sale Offers',
            link: '/product/sale-offers'
        },
        {
            type: 0,
            text: 'View',
        }
    ];

    let history = useHistory();
    let ref = useRef([]);

    useEffect(() => {
        if (id) {
            dispatch(setProductFilter({
                ...productState.filter,
                gridColumnNo: 2
            }));

            dispatch(showSaleOffer(id)).then(res => {
                if (!res.error) {
                    setSaleOffer(res.payload.saleoffer);
                    setProducts(res.payload.products.data);

                    dispatch(setProductPagination(res.payload.products.pagination));

                    setLoading(false);
                    setIsGetProduct(true);
                }
            });
        }
    }, [dispatch]);

    const productFilter = () => {
        if(!isGetProduct) return;

        const filter = productState.filter;
        dispatch(getProducts({
            sort: 'id-desc',
            page_size: filter.pageSize,
            page: filter.currentPage + 1,
            name: filter.name,
            sale_offer_id: id
        })).then(res => {
            setProducts(res.payload);
        });
    }

    const pageChange = (event) => {
        dispatch(setProductFilter({ ...productState.filter, currentPage: event.selected }))
    }

    const onPageSizeChange = (e) => {
        dispatch(setProductFilter({ ...productState.filter, currentPage: 0, pageSize: e.target.value }))
    }

    useEffect(() => {
        productFilter();
    }, [productState.filter.currentPage, productState.filter.name, productState.filter.pageSize]);

    return (
        <Fragment>
            {loading ? <>
                <PreLoader></PreLoader>
            </> : <>
                <Breadcrumb title="View Sale Offer" data={dataMenu} />
                <Container fluid={true} className="product-wrapper">
                    <PrimarySection saleOffer={saleOffer}></PrimarySection>
                    <ProductsSection
                        products={products}
                        productState={productState}
                        pageChange={pageChange}
                        onPageSizeChange={onPageSizeChange}
                        history={history}
                        selectProductRef={ref}></ProductsSection>
                </Container>
            </>
            }
        </Fragment>
    );
}

export default withReducer([{ saleOffer: productReducer }])(ViewSaleOffer);