import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Breadcrumb from "../../../layout/breadcrumb";

import withReducer from "../../../store/withReducer";
import orderReducer from "../store";

import {
    getDirectRefund, storeDirectRefund, setRefundsData
} from "../store/orderSlice";

const RefundOrder = (props) => {

    const dispatch = useDispatch();
    const { id: orderId } = useParams();
    const [shippingRate, setShippingRate] = useState('');
    const [totalRefund, setTotalRefund] = useState('');
    const [buttonDisable, setButtonDisable] = useState(false);
    const orderState = useSelector(({ order }) => order.order);

    const [refundData, setRefundData] = useState({
        currency: 'GBP',
        balance_shipping_rate: 0,
        balance_shipping_rate_format: "0.00",
        balance_refund: 0,
        balance_refund_format: "0.00"
    });

    const formatter = new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatterDecimal = new Intl.NumberFormat('en-UK', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatterDecimalValue = (num) => {
        const formattedNumber = formatterDecimal.format(num); // "1,234,567.89"
        const numberWithoutComma = formattedNumber.replace(/,/g, ''); // "1234567.89"

        return numberWithoutComma;
    }

    /**
     * Form Validation Schema
     */
    const schema = yup.object().shape({
        shipping_refund_amount: yup.number()
            .transform((value, originalValue) =>
                String(originalValue).trim() === "" ? null : value
            )
            .max(refundData.balance_shipping_rate, 'The amount must be ' + refundData.balance_shipping_rate_format + ' or less')
            .nullable(),
        total_refund_amount: yup.number()
            .transform((value, originalValue) =>
                String(originalValue).trim() === "" ? null : value
            )
            .max(refundData.balance_refund, 'The amount must be ' + refundData.balance_refund_format + ' or less')
            .min(0.01, "The amount must be greater than 0")
            .nullable()
            .required("Refund amount is a required field")
    });

    const defaultValues = {
        shipping_refund_amount: null,
        total_refund_amount: null,
        reason: null,
        is_notify: false
    };

    const { handleSubmit, formState, reset, control, register, setValue, trigger, getValues } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { errors, isDirty } = formState;

    const cleanInputData = () => {
        reset();
        setShippingRate('');
        setTotalRefund('');
    }

    const onSubmit = (data) => {
        if (buttonDisable) return;

        setButtonDisable(true);

        let _data = { ...data, ...{ order_id: orderId } };

        dispatch(storeDirectRefund(_data)).then(res => {
            if (!res.error) {
                dispatch(setRefundsData(res.payload.data));
                cleanInputData();
            }

            setButtonDisable(false);
        });
    }

    const dataMenu = [
        {
            type: 1,
            text: 'Orders',
            link: '/order'
        },
        {
            type: 1,
            text: 'View Order',
            link: `/order/${orderId}/view`
        },
        {
            type: 0,
            text: "Refund"
        }
    ];

    const shippingHandleChange = (event) => {
        // Get the value from the input
        let newValue = parseFloat(event.target.value);

        // Round the value to two decimal places
        if (!isNaN(newValue)) {
            newValue = parseFloat(newValue.toFixed(2));
        } else {
            newValue = '';
        }

        setShippingRate(newValue);
        setValue('shipping_refund_amount', newValue);
        trigger('shipping_refund_amount');
    };

    const shippingHandleBlur = (event) => {
        // Get the value from the input
        let newValue = parseFloat(event.target.value);

        // Round the value to two decimal places
        if (!isNaN(newValue)) {
            newValue = formatterDecimalValue(newValue);
        } else {
            newValue = '';
        }

        setShippingRate(newValue);
        setValue('shipping_refund_amount', newValue);
        trigger('shipping_refund_amount');
    };

    const refundHandleChange = (event) => {
        // Get the value from the input
        let newValue = parseFloat(event.target.value);

        // Round the value to two decimal places
        if (!isNaN(newValue)) {
            newValue = parseFloat(newValue.toFixed(2));
        } else {
            newValue = '';
        }

        setTotalRefund(newValue);
        setValue('total_refund_amount', newValue);
        trigger('total_refund_amount');
    };

    const refundHandleBlur = (event) => {
        // Get the value from the input
        let newValue = parseFloat(event.target.value);

        // Round the value to two decimal places
        if (!isNaN(newValue)) {
            newValue = formatterDecimalValue(newValue);
        } else {
            newValue = '';
        }

        setTotalRefund(newValue);
        setValue('total_refund_amount', newValue);
        trigger('total_refund_amount');
    };

    useEffect(() => {
        dispatch(getDirectRefund(orderId)).then(res => {
            if (!res.error) {
                dispatch(setRefundsData(res.payload));
            }
        });
    }, [dispatch]);


    useEffect(() => {
        let shipping_rate = getValues('shipping_refund_amount');
        if (shipping_rate !== null) {
            setTotalRefund(formatterDecimalValue(shipping_rate));
            setValue('total_refund_amount', formatterDecimalValue(shipping_rate));
            trigger('total_refund_amount');
        }

    }, [getValues('shipping_refund_amount')]);

    
    useEffect(() => {
        setRefundData(orderState.refund_balance_details);

    }, [orderState.refund_balance_details]);
    


    let totalShippingRefunded = 0;
    let totalRefunded = 0;

    return (
        <Fragment>
            <Breadcrumb title={"Refund - " + (orderState.data ? orderState.data.order_name : '')} data={dataMenu} />
            <Container fluid={true}>
                {refundData.balance_refund > 0 ?  
                <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md="6">
                            {(refundData && refundData.balance_shipping_rate > 0) &&
                                <Card>
                                    <CardBody>
                                        <h6 className="mb-2">Refund shipping</h6>
                                        <p>Shipping rate: Standard (£{refundData.balance_shipping_rate_format})</p>
                                        <FormGroup className="mt-4">
                                            <Label>Refund amount</Label>
                                            <input type="number" min={0} step="any"
                                                className={'form-control ' + (errors.shipping_refund_amount ? 'is-invalid' : '')}
                                                value={shippingRate}
                                                onChange={shippingHandleChange}
                                                onBlur={shippingHandleBlur} />
                                            {errors.shipping_refund_amount && (
                                                <div className="invalid-feedback">
                                                    {errors.shipping_refund_amount?.message}
                                                </div>
                                            )}
                                        </FormGroup>
                                    </CardBody>
                                </Card>}

                            <Card>
                                <CardBody>
                                    <h6 className="mb-2">Reason for refund</h6>
                                    <FormGroup>
                                        <input type="text" className="form-control"
                                            {...register('reason')} />
                                        <small id="emailHelp" className="form-text text-muted">Only you and other staff can see this reason.</small>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="3">
                            <Card>
                                <CardBody>
                                    {(refundData && refundData.balance_shipping_rate > 0) && <>
                                        <h6 className="mb-2">Summary</h6>
                                        {getValues('shipping_refund_amount') > 0 ? <>
                                            <p>
                                                Shipping
                                                <span className="float-right">{formatter.format(getValues('shipping_refund_amount'))}</span>
                                            </p>
                                        </> : <>
                                            <p>No items selected.</p>
                                        </>}

                                        <hr />
                                    </>}
                                    <h6 className="mb-2">Refund amount</h6>

                                    <FormGroup>
                                        <Label>Shopify Payments</Label>
                                        <input type="number" min={0} step="any"
                                            className={'form-control ' + (errors.total_refund_amount ? 'is-invalid' : '')}
                                            value={totalRefund}
                                            onChange={refundHandleChange}
                                            onBlur={refundHandleBlur} />
                                        <small id="emailHelp" className="form-text text-muted">{formatter.format(refundData.balance_refund)} available for refund</small>
                                        {errors.total_refund_amount && (
                                            <div className="invalid-feedback">
                                                {errors.total_refund_amount?.message}
                                            </div>
                                        )}
                                    </FormGroup>

                                    <div className="checkbox checkbox-dark">
                                        <input id="is_notify" type="checkbox"
                                            onChange={(e) => {
                                                setValue('is_notify', e.target.checked)
                                            }}
                                            defaultChecked={getValues('is_notify')} />
                                        <Label for="is_notify">Send a notification to the customer</Label>
                                    </div>

                                    <hr />

                                    <Button type="submit" color="primary" block={true} disabled={buttonDisable}>
                                        {buttonDisable && <i className="fa fa-spinner fa-spin mr-2"></i>}
                                        Refund {formatter.format(totalRefund)}
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form> : ''}
                
                <Card>
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-dark h6">List of Refunded ({(orderState.data ? orderState.data.order_name : '')})</div>

                        <div className="table-responsive mt-4">
                            <Table className="table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" className="font-weight-bold">Refunded At</th>
                                        <th scope="col" className="font-weight-bold">Refunded By</th>
                                        <th scope="col" className="font-weight-bold">Reason</th>
                                        <th scope="col" className="font-weight-bold text-center">Is Notify</th>
                                        <th scope="col" className="font-weight-bold text-right">Shipping Amount</th>
                                        <th scope="col" className="font-weight-bold text-right">Total Refund Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderState.refunds ? (orderState.refunds.map((refund) => {
                                        totalShippingRefunded += refund.shipping_refund_amount;
                                        totalRefunded += refund.total_refund_amount;
                                        return (
                                            <tr key={refund.id}>
                                                <td>{refund.refunded_at}</td>
                                                <td>{refund.refunded_by}</td>
                                                <td>{refund.reason}</td>
                                                <td className="text-center">{refund.is_notify ? 'Yes' : 'No'}</td>
                                                <td className="text-right">{formatter.format(refund.shipping_refund_amount)}</td>
                                                <td className="text-right">{formatter.format(refund.total_refund_amount)}</td>
                                            </tr>
                                        );
                                    })) : ""}
                                </tbody>

                                {orderState.refunds && orderState.refunds.length >= 2 ?
                                <tfoot>
                                    <tr>
                                        <th className="font-weight-bold text-center" colSpan={4}>Total</th>
                                        <th className="font-weight-bold text-right">{formatter.format(totalShippingRefunded)}</th>
                                        <th className="font-weight-bold text-right">{formatter.format(totalRefunded)}</th>
                                    </tr>
                                </tfoot> : <></> }
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
}

export default withReducer([{ order: orderReducer }])(RefundOrder);