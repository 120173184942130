import {Card, CardBody, CardHeader, Col, FormGroup, Label, Media, Row} from "reactstrap";
import React, {Fragment} from "react";
import {Clock} from "react-feather";
import moment from "moment";

export const CardGreeting = (props) => {
    const {dashboardState,userData}=props;

    const generateGreetings = () =>{
        let currentHour = parseInt(moment().format("HH"));
        let result="Hello";

        if(currentHour >= 3 && currentHour < 12){
            result= "Good Morning";
        }

        if(currentHour>=12 && currentHour <15){
            result= "Good Afternoon";
        }

        if(currentHour >= 15 && currentHour < 20){
            result= "Good Evening";
        }

        if(currentHour >= 20 && currentHour < 23){
            result= "Good Evening";
        }

        return result;

    }
    return (
        <Fragment>
            <Col xl="4" lg="12" md="12" className="morning-sec box-col-12">
                <Card className="o-hidden profile-greeting" style={{backgroundImage:dashboardState.backgroundImage}}>
                    <CardBody>
                        <div className="media">
                            <div className="badge-groups w-100">
                                <div className="badge f-12">
                                    <Clock style={{width:"16px" ,height:"16px"}} className="mr-1"/>
                                    <span id="txt">{moment().format('LLLL')}</span>
                                </div>
                                {/*<div className="badge f-12"><i className="fa fa-spin fa-cog f-14"></i></div>*/}
                            </div>
                        </div>
                        <div className="greeting-user text-center">
                            <div className="profile-vector"><img className="img-80 text-center rounded-circle" alt="user-image" src={userData.photoURL?userData.photoURL:require("../../../assets/images/user/user.png")} /></div>
                            <h4 className="f-w-600"><span id="greeting">{generateGreetings()}</span> <span className="right-circle"><i className="fa fa-check-circle f-14 middle"></i></span></h4>
                            <p><span> {`Today's earning is ${dashboardState.getTodaySale} & your sales increase rate is ${dashboardState.increaseRate} over the last 24 hours`}</span></p>
                            {/*<div className="whatsnew-btn"><a className="btn btn-primary" href="#javascript">{"Whats New !"}</a></div>*/}
                            {/*<div className="left-icon"><i className="fa fa-bell"> </i></div>*/}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};

