import {Card, CardBody, CardHeader, Col, FormGroup, Label, Media, Row} from "reactstrap";
import React, {Fragment} from "react";
import {Clock} from "react-feather";
import moment from "moment";
import '../style/custom.css'
import { useEffect } from "react";
import { retrieveGoogleData } from "../store/dashboardSlice";
import { useDispatch } from "react-redux";

export const GoogleAnalytic = (props) => {
    const {dashboardState, startDate,endDate}=props;
    const dispatch = useDispatch();
    useEffect(() => {
        queryData();
    }, [startDate,endDate]);

    const queryData = () => {
        if(startDate!==null && endDate!==null){
            // isSetMinLoading(true);
            const data = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            }
            dispatch(retrieveGoogleData(data)).then(res => {
                if (!res.error) {
                    
                    // isSetMinLoading(false);
                }
            });
        }
     }

    return (
        <Fragment>
            <Col xl="12" lg="12" md="12" className="morning-sec box-col-12">
            <div className="row" style={{marginTop:'25px', marginBottom:'-25px'}}>
                    <div className="col-xl-6 col-sm-6 col-12">
                        <div className="card shadow border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">Mobile Promotion </span>
                                        <span className="h4 font-bold mb-0">{dashboardState.mobileClick}</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                                            <i className="fa fa-mobile fa-2x"></i>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12">
                        <div className="card shadow border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">Making Video</span>
                                        <span className="h4 font-bold mb-0">{dashboardState.makeVideo}</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                            <i className="fa fa-viadeo"></i>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </Col>
        </Fragment>
    );
};

