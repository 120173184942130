import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { importStockLevelApi, exportInventoryHistoryApi, sellerBrandSuggestionApi, getInventoryReportApi } from "../../../../services/report";
import {
    inventoryReportFilters,
} from "../../../manage-product/product/edit/content/settings/map";

export const getInventoryReportData = createAsyncThunk('report/getInventoryReportData', async (data, { dispatch }) => {
    const response = await getInventoryReportApi(data);
    dispatch(setTableRowCount(response.response.count));
    dispatch(setItems(response.response.data));
    return response.response.data;
});

export const getBrands = createAsyncThunk('product/brand-auto-suggestion', async (data, { dispatch, }) => {
    const response = await sellerBrandSuggestionApi(data);
    return response.response;
});

export const importStockLevel = createAsyncThunk('report/importStockLevel', async (data, { dispatch }) => {
    try {
        const response = await importStockLevelApi(data);
        const responseData = await response.response;
        return responseData;
    } catch (error) {

        return error;
    }
});

export const exportInventoryHistory = createAsyncThunk('report/exportInventoryHistory', async (data, { dispatch, }) => {
    const response = await exportInventoryHistoryApi(data);
    return response.response;
});

const inventoryReportAdapter = createEntityAdapter({});

export const { selectAll: selectInventoryReport, selectById: selectInventoryReportById } = inventoryReportAdapter.getSelectors(
    state => state.report
);

const inventoryReportSlice = createSlice({
    name: 'inventoryReport',
    initialState: inventoryReportAdapter.getInitialState({
        id: null,
        searchText: '',
        inventoryReportTableRow: 0,
        filters: inventoryReportFilters(),
        items: [],
        reportData: null,
        importDialog: {
            title: '',
            type: 1,
            props: {
                isOpen: false,
            },
            data: null
        },
    }),
    reducers: {
        setTableRowCount: (state, action) => {
            state.inventoryReportTableRow = action.payload;
        },
        openImportDialog: (state, action) => {
            state.importDialog = {
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        closeImportDialog: (state, action) => {
            state.importDialog = {
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
        setInventoryReportFilter: (state, action) => {
            state.filters = action.payload;
        },
        setInventoryReportData: (state, action) => {
            state.reportData = action.payload;
        },
        setItems: (state, action) => {
            state.items = action.payload;
        },
    },
    extraReducers: {
        // [getInventoryReportData.fulfilled]: inventoryReportAdapter.setAll
    }
});

export const {
    setTableRowCount,
    openImportDialog,
    closeImportDialog,
    setInventoryReportFilter,
    setInventoryReportData,
    setItems
} = inventoryReportSlice.actions;

export default inventoryReportSlice.reducer;