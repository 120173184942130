import React, { Fragment, useState, useSelector, useEffect } from 'react'
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import ModalFormSubmit from '../../../../../share-components/form/modalFormSubmit';
import { openDialogs, closeDialog, updateAddtional, getAdditionalSettings, setAdditionalDetailsSettings, setAdditionalDetails } from "../../../store/prodcutSlice";
import { useForm } from "react-hook-form";
import AdditionalForm from "../content/additionalDetails-form/additionalForm"
import { useHistory, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    colour: yup.object().shape({ id: yup.string(), }).nullable().required('You must select a Color.'),
    fabric: yup.object().shape({ id: yup.string(), }).nullable().required('You must select a Fabric.'),
    neckline: yup.object().shape({ id: yup.string(), }).nullable().required('You must select a Neckline.'),
    pattern: yup.object().shape({ id: yup.string(), }).nullable().required('You must select a Pattern.'),
    sleeve_length: yup.object().shape({ id: yup.string(), }).nullable().required('You must select a Sleeve Length.'),
    style: yup.object().shape({ id: yup.string(), }).nullable().required('You must select a Style.'),
    occasion: yup.object().shape({ id: yup.string(), }).nullable().required('You must select a Occasion.')
});

function AdditionalDetails(props) {
    const { id } = useParams();
    let history = useHistory();
    const dispatch = useDispatch();

    const defaultValues = {
        description: '',
        colour: '',
        swatch_image: '',
        details_care: '',
        fabric: '',
        neckline: '',
        pattern: '',
        sleeve_length: '',
        style: '',
        occasion: '',
        logoImageDeleted: '',
        swatch_image_url: ''
    };

    const { productStates } = props;

    useEffect(() => {
        if (productStates.addDialogs.props.isOpen) {
            dispatch(getAdditionalSettings(id)).then(res => {
                if (!res.error) {
                    dispatch(setAdditionalDetailsSettings(res.payload.settings));
                    reset(res.payload.additionalDetails);
                }
            });
        }
    }, [dispatch, productStates.addDialogs.props]);

    const {
        control,
        handleSubmit: handleSubmit,
        formState: formState,
        reset: reset,
        register: register,
        setValue: setValue,
        getValues: getValues,
        trigger: trigger,
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(schema)
    });

    const [btnDisable, setBtnDisable] = useState(false);

    const onSubmitForm = (dialog, data) => {
        if (data) {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('description', data.description ? data.description : "");
            formData.append('details_care', data.details_care ? data.details_care : "");
            formData.append('colour', data.colour ? data.colour.id : "");
            formData.append('fabric', data.fabric ? data.fabric.id : "");
            formData.append('neckline', data.neckline ? data.neckline.id : "");
            formData.append('pattern', data.pattern ? data.pattern.id : "");
            formData.append('sleeve_length', data.sleeve_length ? data.sleeve_length.id : "");
            formData.append('style', data.style ? data.style.id : "");
            formData.append('occasion', data.occasion ? data.occasion.id : "");
            formData.append('swatch_image', data.swatch_image ? data.swatch_image : "");
            if (document.getElementById("hiddenFileInput2").value === "true") {
                formData.append('logoImageDeleted', true);
            } else {
                formData.append('logoImageDeleted', false);
            }

            setBtnDisable(true);

            dispatch(updateAddtional(formData)).then(res => {
                console.log(res);
                if (!res.error) {
                    closeDialogAdditional();
                }
                setBtnDisable(false);
            });
        }
    }

    const { errors: errors } = formState;

    const closeDialogAdditional = () => {
        dispatch(closeDialog());
    }

    return (
        <Fragment>

            <ModalFormSubmit
                size={"lg"}
                schema={schema}
                defaultValues={defaultValues}
                onSubmitForm={onSubmitForm}
                reset={reset}
                formState={formState}
                handleSubmit={handleSubmit}
                loadingBtn
                title='Additional Details'
                selector='product'
                dialog={({ product }) => product.product.addDialogs}
                closeDialog={closeDialogAdditional}
                btnDisabled={btnDisable}
            >
                <AdditionalForm
                    productStates={productStates}
                    control={control} errors={errors} trigger={trigger} register={register}
                    setValue={setValue}
                    getValues={getValues}
                ></AdditionalForm>
            </ModalFormSubmit>
        </Fragment>
    )
}

export default AdditionalDetails