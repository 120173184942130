import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import {getData, getGoogleData, topSellingProductsApi} from "../../../services/dashboard";

export const retrieveData = createAsyncThunk('dashboard/retrieveData', async (data,{dispatch})=>{
    try{
        const response = await getData(data);
        dispatch(setData(response.response));
        return response.response;
    } catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }
});

export const retrieveGoogleData = createAsyncThunk('dashboard/retrieveGoogleData', async (data,{dispatch})=>{
    try{
        const response = await getGoogleData(data);
        dispatch(setGoogleData(response.response));
        // console.log(response.response);
        return response.response;
    } catch (e) {
        toast.error("Failed to process request", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return Promise.reject(e);
    }
});

export const topSellingProducts = createAsyncThunk('dashboard/topSellingProducts', async (data, {dispatch}) => {
    const response = await topSellingProductsApi(data);
    return response.response;
});

const dashboardAdapter = createEntityAdapter({});

export const { selectAll: selectDashboard, selectById: selectDashboardById } = dashboardAdapter.getSelectors(
    state => state.dashboard.dashboard
);

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: dashboardAdapter.getInitialState({
        id:null,
        cardChart:null,
        thisMonth:null,
        lastMonth:null,
        monthProfit:null,
        lastOrderUpdateTime:null,
        recentOrders:null,
        recentSellers:null,
        topProducts:null,
        outOfStockProducts:null,
        mobileClick:null,
        makeVideo:null,
        getTodaySale:null,
        increaseRate:null,

    }),
    reducers: {
        setData: (state, action) => {
            state.cardChart = action.payload.data.cardChart;
            state.thisMonth = action.payload.data.thisMonth;
            state.lastMonth = action.payload.data.lastMonth;
            state.monthProfit = action.payload.data.monthProfit;
            state.lastOrderUpdateTime = action.payload.data.lastOrderUpdateTime;
            state.recentOrders = action.payload.data.recentOrders;
            state.recentSellers = action.payload.data.recentSellers;
            state.topProducts = action.payload.data.topProducts;
            state.outOfStockProducts = action.payload.data.outOfStockProducts;
            state.getTodaySale = action.payload.data.getTodaySale;
            state.increaseRate = action.payload.data.increaseRate;

        },
        setGoogleData:(state, action) => {
            state.mobileClick = action.payload.mobile_click;
            state.makeVideo = action.payload.make_video;
        }
        
    },
    extraReducers: {
        [retrieveData.fulfilled]: dashboardAdapter.setAll,
        // [updateStaff.fulfilled]: staffsAdapter.setAll
        // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
    }
});

export const { setData , setGoogleData} =
    dashboardSlice.actions;

export default dashboardSlice.reducer;
