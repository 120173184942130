import API from "../utils/api";

export const getUsOrderApi = async (data)=>{
    const response= await API.get('order/get-all-us-order', {params:data});
    return {response}
};

export const reCreateUSOrderApi = async (data)=>{
    const response= await API.get('order/create-us-order-manually/'+data);
    return {response}
};