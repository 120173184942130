import React, {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOM from 'react-dom';
import {Badge, Card, CardBody, Col, FormGroup, Input, Label} from "reactstrap";
import Select from "react-select";
import JqxTable from "../../../../../share-components/table/JqxTable";
import {toast} from "react-toastify";
import * as ReactDOMServer from "react-dom/server";


const OrderProductJqx = (props) => {
    const {setValue, trigger, getValues, orderedProducts, setButtonDisable} = props;
    let gridRef = useRef(null);
    let selectRef = useRef(null);
    const [orderData, setOrderData] = useState([]);

    const datafields = [
        {name: 'variant_id', type: 'number'},
        {name: 'product_id', type: 'number'},
        {name: 'product_name', type: 'string'},
        {name: 'image', type: 'string'},
        {name: 'sku', type: 'string'},
        {name: 'variant_name', type: 'string'},
        {name: 'quantity', type: 'number'},
        {name: 'price', type: 'number'},
        {name: 'total_price', type: 'number'},
        {name: 'stock', type: 'number'},

    ];

    const columns = [
        {datafield: 'variant_id', hidden: true},
        {datafield: 'stock', hidden: true},
        {
            text: 'Product',
            datafield: 'product_name',
            editable: false,
            columntype: 'text',
            width: '50%',
            cellsrenderer(row, columnfield, value, defaulthtml, columnproperties) {
                const productDetails = getValues('ordered_products')[row];
                const html = ReactDOMServer.renderToString(
                    <div style={{textAlign: 'center', padding: 5}}>
                        {productDetails && <div className="row">
                            <div className="col-md-3">
                                <img className="img-fluid" src={productDetails.image} width="40" alt=""/>
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <p className={'font-primary'}>{value}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.variant_name}</p>
                                </div>
                                <div className="row">
                                    <p>{productDetails.sku}</p>
                                </div>

                            </div>

                        </div>}
                    </div>
                );
                return html;
            },

        },
        {
            text: 'Quantity',
            datafield: 'quantity',
            editable: true,
            columntype: 'text',
            width: '25%',
            cellsalign: 'right',
            validation: function (cell, value) {
                const stock = gridRef.getcellvalue(cell.row, 'stock');
                if (Number(value) <= 0) {
                    setButtonDisable(true);
                    toast.error("Quantity must be greater than zero.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return {result: false, message: "Quantity must be greater than zero."}
                } else if (Number(stock) < Number(value)) {
                    setButtonDisable(true);
                    toast.error("Only "+stock + " is available", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return {result: false, message: "Only "+stock + " is available"}
                } else {
                    setButtonDisable(false);
                    return true;
                }
            },
        },
        {
            text: 'Price',
            datafield: 'total_price',
            editable: false,
            columntype: 'text',
            width: '20%',
            cellsalign: 'right',
            cellsformat: 'c2',
        }
    ];

    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;

            setTimeout(
                () => {
                    if (gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if (myEle) {
                            gridRef.updatebounddata();
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };

    const onDelete = (data) => {
        setDataToHookForm();
    }

    const cellEdit = event => {
        const rowArgs = event.args;
        if(rowArgs.datafield == "quantity"){
            const singlePrice=gridRef.getcellvalue(rowArgs.rowindex,'price');
            gridRef.setcellvalue(rowArgs.rowindex, "total_price", rowArgs.value*singlePrice);
        }
        gridRef.setcellvalue(rowArgs.rowindex, rowArgs.datafield, rowArgs.value);
        setDataToHookForm();
    };

    const setDataToHookForm = () => {
        let orderData = [];

        const gridInformation = gridRef.getdatainformation();
        for (let i = 0; i < gridInformation.rowscount; i++) {
            const rowData = gridRef.getrowdata(i);
            orderData.push(rowData);
        }
        setValue('ordered_products_new', orderData);
        trigger('ordered_products_new');

        setValue('ordered_products', orderData);
        trigger('ordered_products');
    }

    return (
        <Fragment>

            <div style={{width: '100%'}}>
                {getValues('ordered_products').length > 0 && <JqxTable
                    rowsheight={75}
                    datafields={datafields}
                    columns={columns}
                    data={getValues('ordered_products')}
                    myGrid={gridRef}
                    getJqxRef={getJqxRef}
                    isDelete
                    onCellendedit={cellEdit}
                    disableCreateRow
                    onDelete={onDelete}
                    isFirstDelete={true}
                    deleteAll={true}
                />}
            </div>
        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    console.log(JSON.stringify(prevProps.getValues), JSON.stringify(nextProps.getValues));
    return JSON.stringify(prevProps.orderedProducts) == JSON.stringify(nextProps.orderedProducts);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default OrderProductJqx;