import React, {useEffect} from 'react';
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2';



export default function CommonDataTable(props) {
    const {
        headerColumns,
        gridData,
        onEdit,
        onView,
        onDelete,
        noAction=null,
        onSort
    } = props;

    const onConfirmDialogOpen = (row) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this Record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    onDelete(row)
                }
            })
    }


    const columns = [
        ...headerColumns.map(item => {
            return {
                ...item,

            };
        })
    ];


    if(noAction==null){
        columns.push(
        {
            name: 'Action',
            selector: row =>  <>
                {onEdit && <a onClick={() => {
                    onEdit(row);
                }} className="mx-1"><i className="fa fa-pencil" style={{fontSize: 16, color: 'rgb(40, 167, 69)', cursor: 'pointer'}}></i></a>}

                {onView && <a onClick={() => {
                    onView(row);
                }} className="mx-1"><i className="fa fa-eye" style={{fontSize: 16, color: 'rgb(208,179,29)', cursor: 'pointer'}}></i></a>}

                {onDelete && <a onClick={() => {
                    onConfirmDialogOpen(row);
                }} className="mx-1"><i className="fa fa-trash" style={{fontSize: 16, color: '#e4566e', cursor: 'pointer'}}></i></a>}

                {onSort && <a onClick={() => {
                    onSort(row)
                }} className="mx-1"><i className="fa fa-sort" style={{fontSize: 16, color: '#204081', cursor: 'pointer'}}></i></a>}
            </>,
            visible: true,
            sortable: false,
            center: true,
        });
    }


    return (
        <div className="w-full flex flex-col">
            <DataTable
                columns={columns}
                data={gridData}
                highlightOnHover
                pagination
                className="fixed-columns-table"
                {...props}
            />
        </div>
    );
}
