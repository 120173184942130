import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    FormGroup,
    Label,
    Popover,
    PopoverBody,
    Row,
    Table
} from "reactstrap";
import React, {Fragment, useState} from "react";
import moment from "moment";
import {useDispatch} from "react-redux";
import {setProductDialog} from "../../manage-product/store/prodcutSlice";
import {topSellingProducts} from "../store/dashboardSlice";

const PopoverBasicItem = (props) => {
    const {id,btntext,Popoverbody,link,history} = props;
    const [popover, setPopover] = useState(false)
    const Toggle = () => setPopover(!popover);
    return (
        <>
            {link?
                <a id={"Popover-" + id} href="#" onClick={()=>{ history.push('/seller/'+link+'/view')}} className="text-primary">{btntext}</a>
                :
                <p className="example-popover" id={"Popover-" + id}>{btntext}</p>
            }

            <Popover
                placement="bottom"
                isOpen={popover}
                target={"Popover-" + id}
                toggle={Toggle}
                trigger="hover"
            >
                <PopoverBody>
                    {Popoverbody}
                </PopoverBody>
            </Popover>
        </>
    );
};


const textStyles = {
    marginLeft: '10px',
    marginTop: '3px',
    background: '-webkit-linear-gradient(#000000, #d2b2b2)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };


export const CardTopSoldProducts = (props) => {
    const {dashboardState,history,startDate,endDate, seller}=props;
    const dispatch = useDispatch();

    const exportTopSellingProducts = () => {
        const TodayDate = new Date();
        const lastThirtyDays = new Date().setDate(TodayDate.getDate() - 30);

        const data = {
            start_date: startDate?(moment(startDate).format('YYYY-MM-DD')):moment(lastThirtyDays).format('YYYY-MM-DD'),
            end_date: endDate?(moment(endDate).format('YYYY-MM-DD')):moment(TodayDate).format('YYYY-MM-DD'),
            seller: seller? seller.value:''
        }
        const outputFilename = `top_selling_products_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(topSellingProducts({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        });
    
    }

    return (
        <Fragment>
            {dashboardState.topProducts.length>0?
            <Col style={{height:"min-content"}}>
                <Card className="h-75">
                    <CardHeader>
                    <Row>
                        <h5> Top Sold Products</h5>
                        <h6 style={textStyles}>{seller? seller.label:''} </h6>
                    </Row>
                       
                        <p className="font-roboto mt-0 pt-0"><b className="font-roboto">Overview from </b>{startDate?(moment(startDate).format('YYYY-MM-DD')):""} to {endDate?(moment(endDate).format('YYYY-MM-DD')):""}</p>
                    </CardHeader>
                    <CardBody className="overflow-auto">

                        <div className="table-responsive">
                            <Table className="table table-borderless table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Brand</th>
                                    <th scope="col">Price (£)</th>
                                    <th scope="col">Count</th>
                                    <th scope="col">View</th>
                                </tr>
                                </thead>
                                <tbody>
                                {dashboardState.topProducts.map((item, i) =>
                                    <tr key={i}>
                                        <th width="30%" scope="row">{item.popoverProduct?<PopoverBasicItem id={"top-sold-"+i} Popoverbody={item.product} btntext={item.popoverProduct}/>:item.product}</th>
                                        <td width="20%">{item.brand}</td>
                                        <td width="15%">{item.price}</td>
                                        <td width="10%">{item.count}</td>
                                        <td width="5%"><button onClick={()=>{ let obj = {props: {isOpen: true}, data: {id:item.id }};
                                            dispatch(setProductDialog(obj))}} className="btn btn-light"><i className= "icon-eye"></i></button></td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <div className="mt-2 d-flex justify-content-end">
                            <div className="p-2">
                                <button onClick={()=>{ exportTopSellingProducts() }} type="button" className="btn btn-success">Download Top 50 Products</button>
                            </div>
                            <div className="p-2">
                                <button onClick={()=>{history.push('/product')}}  type="button" className="btn btn-primary">View All Products</button>
                            </div>
                        </div>
                    </CardFooter>
                </Card>
            </Col>:""}
        </Fragment>
    );
};