import API from "../utils/api";


export const getSellerReportDataApi = async (data) => {
  const response = await API.get('report/sales-report',{
      params: data,
  });
  return {response};
};

export const getBackInStockReportApi = async (data) => {
    const response = await API.get('report/back-in-stock-report',
        {
            params: data,
        });
    return {response};
}

export const getSelectedBackInRequestData = async (id) => {
    const response = await API.get(`report/get-selected-back-in-stock/${id}`);
    return {response};
};

export const getExchangeOrderReportApi = async (data) => {
    const response = await API.get(`order/get-all-exchange-orders`, {params:data});
    return {response};
}

export const offerItemReportApi = async (data) => {
    const response = await API.get('report/offer-report', {params:data});
    return {response};
}

export const getInventoryReportApi = async (data) => {
    const response = await API.get(`report/inventories-report`,{
        params: data,
    });
    return { response };
};

export const importStockLevelApi = async (data) => {
    const response = await API.post('import-stock-level', data, {'content-type': 'multipart/form-data'});
    return {response};
}

export const exportInventoryHistoryApi = async (data) => {
    const response = await API.get(`report/inventories-report/export`, {
        params: data,
    });
    return { response };
};

export const sellerBrandSuggestionApi = async (text) => {
    const response = await API.get('product/brand-auto-suggestion', {
        params: text,
    });
    return { response };
};