import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, FormGroup, Row, Form, Input, Popover, PopoverBody, PopoverHeader} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import {DateRangePicker} from "rsuite";
import moment from "moment";
import orderReducer from "../store";
import {getUsOrder, selectUsOrders, reCreateUSOrder} from "../store/usOrderSlice";
import {useHistory, Link} from "react-router-dom";
import Select from "react-select";

const {afterToday} = DateRangePicker;


function UsOrder() {
    const dataMenu = [
        {
            type: 0,
            text: "Goddiva US Site Orders"
        }
    ];

    const dispatch = useDispatch();
    const usOrderState = useSelector(({order}) => order.usOrder);
    const usOrdersGridData = useSelector(selectUsOrders);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [timer, setTimer] = useState(null);
    const [page, setPage] = useState(1);
    const [tableLoading, isSetTableLoading] = useState(false);
    const [loading, isSetLoading] = useState(false);
    const [startDate, endDate] = dateRange;
    const [pageSize, setPageSize] = useState(10);
    let history = useHistory();
    const [status, setStatus] = useState(null);
    const [usOrder, setUsOrder] = useState(null);
    const [draftOrder, setDraftOrder] = useState(null);
    const [ukOrder, setUkOrder] = useState(null);
    const [fulfilmentStatus, setFulfilmentStatus] = useState(null);
    const [loadingIcon, setLoadingIcon] = useState(false);


    let tableColumns = [
       
        /*{
            name: 'Id',
            selector: row => row.id,
            cell: (row) =><Link className="text-primary" target="_blank" style={{cursor: "pointer"}} >{row.id}</Link>,
            sortable: true,
            center: false,
        }, 
        {
            name: 'Order number',
            selector: row => row.orderNumber,
            sortable: true,
            center: false,
        },*/
        {
            name: 'US Order name',
            selector: row => row.orderName,
            sortable: true,
            center: false,
        },
        {
            name: 'Date',
            selector: row => row.orderDate,
            sortable: true,
            center: false,
        },
        
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: false,
        },
        {
            name: 'Draft order',
            selector: row => row.draft_order_name,
            cell: (row) => (row.draft_order_db_id) ? <Link target="_blank" className="text-primary" style={{cursor: "pointer"}} to={`${process.env.PUBLIC_URL}/order/draft`}>{row.draft_order_name}</Link> : "",
            sortable: true,
            center: false,
        },
        {
            name: 'UK order name',
            selector: row => row.uk_order_name,
            cell: (row) => (row.uk_order_db_id) ? <Link target="_blank" className="text-primary" style={{cursor: "pointer"}} to={`${process.env.PUBLIC_URL}/order/${row.uk_order_db_id}/view`}>{row.uk_order_name}</Link> : "",
            sortable: true,
            center: false,
        },
        {
            name: 'Fulfilment Status',
            selector: row => row.fulfilment_status,
            sortable: true,
            center: false,
        },
        {
            name: 'Action',
            selector: (row) => {
                const errors = row.errors;
                if (errors) {
                    return (
                        <PopoverError id={row.id} Popoverbody={errors} row={row} />
                    );
                } else {
                    return null;
                }
            },
            sortable: true,
            center: false,
        }
        
    ];

    const PopoverError = (props) => {
        const {id,row,Popoverbody} = props;
        const [popover, setPopover] = useState(false)
        const Toggle = () => setPopover(!popover);
        return (
            <>
                <span style={{cursor:"pointer", fontSize:"18px", display:"inline-block", margin:"0 10px 0 0"}} className="example-popover" id={"Popover-OrderId-" + id}> <i className="fa fa-info-circle text-danger"></i></span>
                {
                    (loadingIcon)
                    ? <span style={{cursor:"pointer", fontSize:"18px"}}> 
                    <i className="fa fa-refresh fa-spin" aria-hidden="true"></i>
                    </span>
                    : <a href={undefined} onClick={() => reCreateOrder(row.orderNumber)}><span style={{cursor:"pointer", fontSize:"18px"}}> 
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                    </span></a>
                }
                
                <Popover
                    placement="top"
                    isOpen={popover}
                    target={"Popover-OrderId-" + id}
                    toggle={Toggle}
                    trigger="hover"
                >
                    <PopoverBody>
                        <pre>{JSON.stringify(Popoverbody, null, 4)}</pre>
                    </PopoverBody>
                </Popover>
            </>
        );
    };

    const reCreateOrder = (orderId) => {
        setLoadingIcon(true);
        dispatch(reCreateUSOrder(orderId)).then(response => {
            if(!response.error){
                getData();
            }
            setLoadingIcon(false);
        });
    }

    useEffect(()=> {
        getData();
    },[dispatch, startDate, endDate,page, pageSize, status, usOrder, draftOrder, ukOrder, fulfilmentStatus]);

    const getData = () =>{
        if(startDate!==null && endDate!==null) {
            isSetTableLoading(true);
            const data = {
                page: page,
                pageSize: pageSize,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                status: status, 
                usOrder: usOrder, 
                draftOrder: draftOrder, 
                ukOrder: ukOrder,
                fulfilmentStatus: fulfilmentStatus

            }
            dispatch(getUsOrder(data)).then(res=>{
                if(!res.error){
                    isSetLoading(false);
                }
                isSetTableLoading(false);
            });
        }
    }

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 50,
            border: 'none',
        })
    };

    const statusOptions = [
        {value: '1 ', label: 'Draft Order Created'},
        {value: '2', label: 'Order Created'},
        {value: '3', label: 'Out Of Stock'},
        {value: '4', label: 'SKU Not Found'},
    ];

    const fulfilmentStatusOptions = [
        {value: '1 ', label: 'Unfulfilled'},
        {value: '2', label: 'Fulfilled'},
        {value: '3', label: 'Partially Fulfilled'},
        {value: '4', label: 'Cancelled'},
    ];

  return (
    <Fragment>

        <Breadcrumb title="Goddiva US Site Orders" data={dataMenu}/>
        <Container fluid={true} className="product-wrapper">
            <div className="product-grid">
                <div className="feature-products">
                    <Row>
                        <Col sm="2">
                            <div className="dashboard-datepicker">
                                <DatePicker
                                    dateRange={dateRange}
                                    dateRangeChange={(date) => {
                                        setDateRange(date)
                                    }}
                                    defaultValue={[TodayDate, sevenDays]}
                                    disabledDate={afterToday()}
                                    paddingLeft={10}
                                />
                            </div>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Select
                                    placeholder={<div>Fulfilment Status</div>}
                                    className="select2-filter"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles}
                                    options={fulfilmentStatusOptions}
                                    onChange={(e) => {
                                        setFulfilmentStatus(e ? e.value : null)
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Select
                                    placeholder={<div>Status</div>}
                                    className="select2-filter"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles}
                                    options={statusOptions}
                                    onChange={(e) => {
                                        setStatus(e ? e.value : null)
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col sm="2">
                            <Form  onSubmit={(e)=>{
                                e.preventDefault();
                            }}>
                                <FormGroup className="m-0">
                                    <Input
                                        name="us_order"
                                        type="text"
                                        className="form-control"
                                        onChange={(e)=>{
                                            clearTimeout(timer);
                                            const newTimer = setTimeout(()=>{
                                                setUsOrder(e.target.value);
                                            },500);
                                            setTimer(newTimer)
                                        }}
                                        placeholder="Search US Order id"
                                    />
                                    <i className="fa fa-search"></i>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col sm="2">
                            <Form  onSubmit={(e)=>{
                                e.preventDefault();
                            }}>
                                <FormGroup className="m-0">
                                    <Input
                                        name="uk_draft_order"
                                        type="text"
                                        className="form-control"
                                        onChange={(e)=>{
                                            clearTimeout(timer);
                                            const newTimer = setTimeout(()=>{
                                                setDraftOrder(e.target.value);
                                            },500);
                                            setTimer(newTimer)
                                        }}
                                        placeholder="Search Draft Order id"
                                    />
                                    <i className="fa fa-search"></i>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col sm="2">
                            <Form  onSubmit={(e)=>{
                                e.preventDefault();
                            }}>
                                <FormGroup className="m-0">
                                    <Input
                                        name="uk_order"
                                        type="text"
                                        className="form-control"
                                        onChange={(e)=>{
                                            clearTimeout(timer);
                                            const newTimer = setTimeout(()=>{
                                                setUkOrder(e.target.value);

                                            },500);
                                            setTimer(newTimer)
                                        }}
                                        placeholder="Search UK Order id"
                                    />
                                    <i className="fa fa-search"></i>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" className="mt-3">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={usOrdersGridData}
                                            noAction
                                            paginationServer
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10,25,50,100]}
                                            onChangePage={page => setPage(page)}
                                            onChangeRowsPerPage={(pageSize) => setPageSize(pageSize)}
                                            paginationTotalRows={usOrderState.usOrderTableRow}
                                            progressPending={tableLoading}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>



    </Fragment>
  )
}

export default withReducer([{order: orderReducer}])(UsOrder);
