import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as SweetAlert from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import Breadcrumb from "../../../../layout/breadcrumb";
import orderReducer from "../../store";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";
import PreLoader from "../../../../share-components/ui/preLoader";
import AlterationRequestJqx from "./content/AlterationRequestJqx";
import withReducer from "../../../../store/withReducer";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import TrackingForm from "./content/TrackingForm";
import LogActivity from "../../../log";

import "react-datepicker/dist/react-datepicker.css";

import {
    editAlterationRequest,
    updateAlterationRequest,
    openAlterationTrackingDialog
} from "../../store/orderSlice";

let gridRef

const EditAlterationRequest = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const orderState = useSelector(({ order }) => order.order);
    const [loading, isSetLoading] = useState(true);
    const authData = useSelector(({ authReducer }) => authReducer);
    const [alterationFormData, setAlterationFormData] = useState(null);
    const [tableLoading, isSetTableLoading] = useState(false);

    const dataMenu = [
        {
            type: 1,
            text: 'Alteration Requests',
            link: '/order/alteration-requests'
        },
        {
            type: 0,
            text: "Edit"
        }
    ];

    const defaultValuesTracking = {
        deliver_partner: '',
        tracking_id: '',
        tracking_url: ''
    };
    const [btnDisabled, setBtnDisabled] = useState(false);
    const urlValidation = /^(https?:\/\/)/;
    const schemaTracking = yup.object().shape({
        tracking_id: yup.string().required('Tracking ID is required field').typeError('Tracking ID is required field'),
        tracking_url: yup.string().matches(urlValidation, "Entered URL is invalid").required('Tracking URL is required field').typeError('Tracking URL is required field'),
        deliver_partner: yup.object().required('Deliver Partner is required field').typeError('Deliver Partner is required field'),
    });

    const onSubmitFormTracking = (dialog, data) => {
        const trackingData = {
            "tracking_id": data.tracking_id,
            "tracking_url": data.tracking_url,
            "id": data.id,
            "tracking_name": data.deliver_partner.name
        }
        const formData = { ...alterationFormData, ...{ 'trackingData': trackingData } }
        updateAlteration(formData);
    }

    const { handleSubmit: handleSubmitTracking, formState: formStateTracking, reset: resetTracking, register: registerTracking, setValue: setValues, getValues: getValuesTracking, trigger: triggers } = useForm({
        mode: 'onChange',
        defaultValues: defaultValuesTracking,
        resolver: yupResolver(schemaTracking)
    });

    const { errors: errorsTracking } = formStateTracking;

    const onCloseTrackingModal = () => {
        resetTracking(defaultValuesTracking);
        setAlterationFormData(null);
        dispatch(openAlterationTrackingDialog(false));
    }

    useEffect(() => {
        const requestParmas = {
            params: {
                seller: authData.defaultSeller?.value
            },
            id: id
        }
        isSetTableLoading(true);
        dispatch(editAlterationRequest(requestParmas)).then(res => {
            if (!res.error) {
                isSetLoading(false);
            }
            isSetTableLoading(false);
        });
    }, [dispatch, authData.defaultSeller]);

    const getSelectedRowData = () => {
        let data = [];
        gridRef.getselectedrowindexes().forEach(i => {
            const rowData = gridRef.getrowdata(i);
            data.push(rowData);
        });
        return data;
    };

    const submitData = () => {
        if (gridRef) {
            const gridData = getSelectedRowData();

            if (gridData.length < 1) {
                toast.error("You must select at least one item.", {
                    position: toast.POSITION.TOP_RIGHT,
                });

            } else {

                const items = gridData.map((item) => {
                    return {
                        id: item.id,
                        is_received: !!item.is_received,
                        is_completed: !!item.is_completed,
                        is_decline: !!item.is_decline,
                    };
                });

                const isEmpty = items.some(item => !item.is_received && !item.is_completed && !item.is_decline);

                if (isEmpty) {
                    toast.error("Please fill all required fields", {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                } else {
                    const formData = {
                        id: orderState.alterationRequestData.data.id,
                        items: items
                    };
                    const isTrackingRequest = items.some(item => item.is_completed || item.is_decline);

                    if (isTrackingRequest) {
                        setAlterationFormData(formData);
                        dispatch(openAlterationTrackingDialog(true));

                    } else {
                        SweetAlert.fire({
                            title: 'Are you sure want to process this alteration request?',
                            text: "Once confirmed, the alteration request will be sent to the system",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Yes, Proceed',
                            cancelButtonText: 'No, cancel',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                updateAlteration(formData);
                            }
                        });
                    }

                }

            }
        }
    };

    const updateAlteration = (data) => {
        setBtnDisabled(true);
        dispatch(updateAlterationRequest(data)).then(res => {
            if (!res.error) {
                setBtnDisabled(false);
                history.push('/order/alteration-requests');
            }
        });
    }

    const getJqx = (jqx) => {
        gridRef = jqx;
    }

    const onView = (row) => {
        history.push(`/order/alteration-requests/${row.id}/view`);
    };

    // Tracking   
    let trackingTableColumns = [
        {
            name: 'ID',
            selector: row => row.id,
            width: "100px",
            sortable: false,
            center: false,
        },
        {
            name: 'Tracking Name',
            selector: row => row.tracking_name,
            sortable: false,
            center: false,
        },
        {
            name: 'Tracking ID',
            selector: row => row.tracking_id,
            sortable: false,
            center: false,
        },
        {
            name: 'Tracking URL',
            selector: row => row.tracking_url,
            sortable: false,
            center: false,
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: false,
            center: false,
        },
        {
            name: 'Updated At',
            selector: row => row.updated_at,
            sortable: false,
            center: false,
        },
    ];

    // data provides access to your row data
    const expandedChildItems = ({ data }) => {
        const childTableColumns = [
            {
                name: 'Product',
                selector: row => row.product_name,
                cell: (row) => (
                    <div style={{ width: '100%', padding: '10px 0' }}>
                        <div style={{ width: '20%', float: 'left', paddingRight: '10px', textAlign: 'center' }}>
                            <img className="img-fluid" src={row.image} width="40" alt="" />
                        </div>
                        <div style={{ width: '80%', float: 'left' }}>
                            <p className={'font-primary'}>{row.product_name}</p>
                            <p style={{ lineHeight: 1.2, marginTop: "5px" }}>{row.colour}/{row.size}</p>
                            <p style={{ lineHeight: 1.2, marginTop: "5px" }}>{row.sku}</p>
                            <p style={{ lineHeight: 1.2, marginTop: "5px" }}>{row.brand_name}</p>
                        </div>
                    </div>
                ),
                width: "40%",
                sortable: false,
                center: false,
            },
            {
                name: 'SKU',
                selector: row => row.sku,
                sortable: false,
                center: false,
            },
            {
                name: 'Ordered Qty',
                selector: row => row.ordered_quantity,
                width: "10%",
                sortable: false,
                center: false,
            },
            {
                name: 'Requested Qty',
                selector: row => row.request_quantity,
                width: "10%",
                sortable: false,
                center: false,
            },
            {
                name: 'Status',
                selector: row => row.status_id,
                cell: (row) => (
                    <div>
                        <span className={`badge px-3 ${{
                            1: 'badge-secondary',
                            2: 'badge-primary',
                            3: 'badge-success',
                        }[row.status_id] || 'badge-danger'}`}>
                            {row.status}
                        </span>
                    </div>
                ),
                width: "200px",
                sortable: false,
                center: true,
            },
        ];

        const childData = data.items;

        return (
            <div className="px-5 pt-3 pb-5 border-bottom">
                <CommonDataTable
                    className="refunded-histories-child-table"
                    headerColumns={childTableColumns}
                    data={childData}
                    noAction
                    pagination={false}
                />
            </div>
        );
    };

    return (
        <Fragment>
            {loading ? <>
                <PreLoader></PreLoader>
            </> : <>
                <Breadcrumb title="Edit Alteration Request" data={dataMenu} />
                <Container fluid={true}>

                    <div style={{position: 'fixed', top: '50%', zIndex: 10,right:0}}>
                        <LogActivity log="Alteration Request" id={id}></LogActivity>
                    </div>

                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <h5 className="mb-2">List of Requested Items</h5>

                                    <AlterationRequestJqx getGridJqx={getJqx} orderState={orderState}></AlterationRequestJqx>

                                    <div className="mt-2 d-flex justify-content-end">
                                        <div className="p-2">
                                            <div className="d-flex">
                                                <ButtonLoader color="primary" disabled={btnDisabled} onClick={() => {
                                                    submitData()
                                                }} btntext="Save" className="btn btn-primary" />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    {orderState.alterationRequestData.data.trackings.length > 0 && (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="">
                                            <h5 className="mb-2">List of Trackings</h5>
                                        </Col>
                                        <Col md="1">
                                            <a
                                                onClick={() => onView(orderState.alterationRequestData.data)}
                                                className={`btn btn-info btn-sm px-2 py-1 mb-2 float-right`}
                                            >
                                                <i className="fa fa-eye"></i> Open View
                                            </a>
                                        </Col>
                                    </Row>

                                    <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={trackingTableColumns}
                                            progressPending={tableLoading}
                                            data={orderState.alterationRequestData.data.trackings}
                                            noAction
                                            pagination={false}
                                            expandableRows
                                            expandableRowsComponent={expandedChildItems}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    )}
                </Container>
            </>}

            {/* Tracking Information */}
            <ModalFormSubmit
                index={0}
                defaultValues={defaultValuesTracking}
                schema={schemaTracking}
                onSubmitForm={onSubmitFormTracking}
                reset={resetTracking}
                formState={formStateTracking}
                handleSubmit={handleSubmitTracking}
                btnDisabled={btnDisabled}
                loadingBtn
                title='Tracking'
                selector='order'
                dialog={({ order }) => order.order.alterationTrackingDialog}
                closeDialog={onCloseTrackingModal}
            >
                <TrackingForm orderState={orderState} trigger={triggers} errors={errorsTracking} register={registerTracking} setValue={setValues} getValues={getValuesTracking} />
            </ModalFormSubmit>
        </Fragment>
    );
}

export default withReducer([{ order: orderReducer }])(EditAlterationRequest);