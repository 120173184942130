import React, { Fragment, useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  Popover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";
import restockReportReducer from "../store";
import withReducer from "../../../store/withReducer";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  mapRestockReportResponseToTable,
  mapSearchAutoSuggestion,
} from "../../../utils/mapper";
import { RestockStatusAutoSuggestionApi } from "../../../services/order";

import {
  getReturnRestockListReport,
  setRestockReportFilter,
  setReturnRestockData,
  selectReturnRestockReport, exportReturnRestockReport,
} from "../store/restockReportSlice";

import CommonDataTable from "../../../share-components/table/commonDataTable";
import ButtonLoader from "../../../share-components/ui/buttonLoader";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import "./style.scss";
import { Columns } from "react-feather";
import { mapRestockReportSearchAutoSuggestion } from "../../../utils/mapper";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import {
  userAutoSuggestionApi,
  getUsersApi,
} from "../../../services/manage-user/user";

const { afterToday } = DateRangePicker;

function RestockReport() {
  const dispatch = useDispatch();
  let history = useHistory();

  const TodayDate = new Date();
  const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
  const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
  const [startDate, endDate] = dateRange;
  const [timer, setTimer] = useState(null);
  const restockState = useSelector(({ order }) => order.restockReport);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const authData = useSelector(({ authReducer }) => authReducer);
  const [retrunRestockDatas, setRetrunRestockDatas] = useState([]);
  const [restockStatus, setRestockStatus] = useState(null);
  const [restockedByUser, setRestockedByUser] = useState(null);


  // const returnRestockReport = useSelector(selectReturnRestockReport);
  // const [loading, isSetLoading] = useState(history.location.state?.from !== "update" || returnRestockReport.length < 1);
  const [btnDisable, setBtnDisable] = useState(false);


  // Function to update a specific row's data
  const updateRowData = (id, newData) => {
    const updatedData = retrunRestockDatas.map((row) =>
      row.id === id ? { ...row, ...newData } : row
    );
    setRetrunRestockDatas(updatedData);

    // Update for seleted data
    const updatedSeletedData = selectedRows.map((row) => {
      let dataIndex = updatedData.findIndex((x) => x.id == row.id);

      return dataIndex === -1 ? row : updatedData[dataIndex];
    });
    setSelectedRows(updatedSeletedData);
  };

  const getData = () => {
    const filters = restockState.filters;

    if (
      authData.defaultSeller &&
      JSON.stringify(filters) !=
        JSON.stringify({
          ...restockState.filters,
          seller: authData.defaultSeller,
        })
    )
      return;

    dispatch(
      getReturnRestockListReport({
        page: filters.currentPage,
        pageSize: filters.pageSize,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        seller: filters.seller ? filters.seller.value : "",
        order: filters.order,
        return_requested_qty: filters.return_requested_qty,
        restock_history: filters.restock_history,
        restocked_qty: filters.restocked_qty,
        sku: filters.sku,
        order_number: filters.order_number,
        restock_status: filters.restock_status,
        remaining_qty: filters.remaining_qty,
        orderNumber: filters.orderNumber,
        restockStatus: filters.restockStatus,
        restockedBy: filters.restockedBy,
      })
    ).then((res) => {
      dispatch(setReturnRestockData(res.payload));
      setRetrunRestockDatas(mapRestockReportResponseToTable(res.payload));
    });
  };

  useEffect(() => {
    getData();
  }, [
    restockState.filters.currentPage,
    restockState.filters.pageSize,
    startDate,
    endDate,
    restockState.filters.order,
    restockState.filters.sku,
    restockState.filters.bin_number,
    restockState.filters.seller,
    restockState.filters.restock_by,
    restockState.filters.restock_status,
    restockState.filters.restock_at,
    restockState.filters.ordered_qty,
    restockState.filters.order_number,
    restockState.filters.remaining_qty,
    restockState.filters.return_requested_qty,
    restockState.filters.restock_history,
    restockState.filters.seller,
    restockState.filters.orderNumber,
    restockState.filters.restockStatus,
    restockState.filters.restocked_by,
    restockState.filters.restockedBy,
  ]);

  useEffect(() => {
    if (authData.defaultSeller?.value != restockState.filters.seller?.value) {
      dispatch(
        setRestockReportFilter({
          ...restockState.filters,
          seller: authData.defaultSeller,
          currentPage: 1,
        })
      );
    }
  }, [authData.defaultSeller]);

  const dataMenu = [
    {
      type: 1,
      text: "Orders",
      link: "/order",
    },
    {
      type: 0,
      text: "Restock Report",
    },
  ];

  const tableColumns = [
    {
      name: "Last Restocked At",
      selector: (row) => row.lastRestockAt,
      sortable: false,
      center: false
    },
    {
      name: "Order Number",
      selector: (row) => row.order_number,
      sortable: false,
      center: false,
    },
    {
      name: "SKU",
      selector: (row) => row.sku,
      sortable: false,
      center: false,
    },
    {
      name: "Restock Status",
      selector: (row) => row.restock_status,
      sortable: false,
      center: false,
    },
    {
      name: "Return Requested Quantity",
      selector: (row) => row.return_requested_qty,
      sortable: false,
      center: true,
    },
    {
      name: "Restocked Quantity",
      selector: (row) => row.restocked_qty,
      sortable: false,
      center: true,
    },
    {
      name: "Remaining Quantity",
      selector: (row) => row.remaining_qty,
      sortable: false,
      center: true,
    },
  ];

  const pageChange = (e) => {
    dispatch(
      setRestockReportFilter({ ...restockState.filters, currentPage: e })
    );
  };

  const pagination = (e) => {
    dispatch(
      setRestockReportFilter({
        ...restockState.filters,
        currentPage: 1,
        pageSize: e,
      })
    );
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 50,
      border: "none",
    }),
  };

  const handleRowSelected = (state) => {
    const returnData = [...retrunRestockDatas];
    const items = state.selectedRows.map((row) => {
      let dataIndex = returnData.findIndex((x) => x.id == row.id);
      if (dataIndex !== -1) {
        let item = { ...returnData[dataIndex] };
        row.completeRestock = item.completeRestock;
      }

      return row;
    });

    setSelectedRows(items);
  };

  //This is for filter using restocked by
  const userPromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(mapSearchAutoSuggestion(getUsersApi(data)));
      });
    });

  //This is for filter using restockStatus
  const RestockStatus = [
    { value: "declined ", label: "Declined" },
    { value: "restocked", label: "Restocked" },
    { value: "partially_restocked", label: "Partially Restocked" },
  ];

  //This is for Export Data
  // const exportRestockReport = () => {
  //     isSetLoading(true)
  //     const data = {
  //       dateRangeX:dateRange[0]!==null?moment(dateRange[0]).format('YYYY-MM-DD'):null,
  //       dateRangeY:dateRange[1]!==null?moment(dateRange[1]).format('YYYY-MM-DD'):null,
  //         order_number:restockState.filter.order_number?restockState.filter.order_number.id:'',
  //         sku:restockState.filter.sku?restockState.filter.sku.id:'',
  //         restock_status:restockState.filter.restock_status?restockState.filter.restock_status.id:'',
  //         return_requested_qty:restockState.filter.return_requested_qty?restockState.filter.sku.return_requested_qty:'',
  //         remaining_qty:restockState.filter.remaining_qty?restockState.filter.remaining_qty.id:'',
  //         restocked_by:restockState.filter.restocked_by?restockState.filter.restocked_by.id:'',
  //         restockedBy:restockState.filter.restockedBy?restockState.filter.restockedBy:'',
  //         restock_history:restockState.filter.restock_history?restockState.filter.restock_history.id:'',
  //         pageType:'report',
  //     }
  //     const outputFilename = `return-restock-report${(new Date().toJSON().slice(0, 10))}.xlsx`;
  //     dispatch(exportReturnRestockReport({...data})).then(res => {
  //         const url = window.URL.createObjectURL(new Blob([res.payload]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', outputFilename);
  //         document.body.appendChild(link);
  //         link.click();
  //         isSetLoading(false)
  //     });
  // }

  const exportRestockReport = () => {
    setBtnDisable(true);
    
    const filters = restockState.filters;

    const data = {      
      page: filters.currentPage,
      pageSize: filters.pageSize,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      seller: filters.seller ? filters.seller.value : "",
      order: filters.order,
      return_requested_qty: filters.return_requested_qty,
      restock_history: filters.restock_history,
      restocked_qty: filters.restocked_qty,
      sku: filters.sku,
      order_number: filters.order_number,
      restock_status: filters.restock_status,
      remaining_qty: filters.remaining_qty,
      orderNumber: filters.orderNumber,
      restockStatus: filters.restockStatus,
      restockedBy: filters.restockedBy,
      pageType:'report',
    }
    
    dispatch(exportReturnRestockReport({...data})).then(res => {
      const downloadLink = document.createElement('a');
      downloadLink.href = res.payload;
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up by removing the download link from the document body
      document.body.removeChild(downloadLink);
      setBtnDisable(false);
    });
}


  // data provides access to your row data
  const ExpandedComponent = ({ data }) => {
    const childTableColumns = [
      {
        name: "Restocked By",
        selector: (row) => ucfirst(row.restock_by),
        sortable: false,
        center: false,
      },
      {
        name: "Restocked Quantity",
        selector: (row) => row.restocked_qty,
        sortable: false,
        center: false,
        cell: (row) => {
          if(Array.isArray(row.restocked_qty)){
            return (
              <div className="my-2">
                {row.restocked_qty.map((qty, i) => (
                  <div key={i}>{`${qty.bin_number} (${qty.quantity})`}</div>
                ))}
              </div>
            )
          }else if(row.restocked_qty == null && row.declined_qty != null){
            return (<div>Declined ({row.declined_qty})</div>)
          }else{
            return (<div>{row.restocked_qty}</div>)
          }
          /*return Array.isArray(row.restocked_qty) ? (
            <div className="my-2">
              {row.restocked_qty.map((qty, i) => (
                <div key={i}>{`${qty.bin_number} (${qty.quantity})`}</div>
              ))}
            </div>
          ) : (
            <div>{row.restocked_qty}</div>
          );*/
        },
      },
      {
        name: "Restocked At",
        selector: (row) => row.restock_at,
        sortable: false,
        center: false,
      },
    ];

    const childData = data.restock_history;

    return (
      <div className="px-5 pt-3 pb-5 border-bottom">
        <CommonDataTable
          headerColumns={childTableColumns}
          data={childData}
          noAction
          pagination={false}
        />
      </div>
    );
  };

  function ucfirst(str) {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  }

  return (
    <Fragment>
      <Breadcrumb title="Return Restock Report" data={dataMenu} />
      <Container fluid={true}>
        <Row>
          <Col sm="12" marginTop="2">
            <div className='float-sm-right'>
              <Button 
                color="primary" 
                className="btn btn-primary btn-square"
                disabled={btnDisable}
                onClick={() => { exportRestockReport() }}>
                { btnDisable ? <i className="fa fa-spinner fa-spin spinner mr-1" /> : '' }
                Export as Excel
              </Button>
            </div> 
          </Col>
          
          <Col sm="12">
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col md="">
                    <div
                      style={{ marginTop: 7 }}
                      className="dashboard-datepicker"
                    >
                      <DatePicker
                        dateRange={dateRange}
                        dateRangeChange={(date) => {
                          setDateRange(date);
                        }}
                        defaultValue={[TodayDate, sevenDays]}
                        disabledDate={afterToday()}
                      />
                    </div>
                  </Col>

                  <Col md="">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <FormGroup
                        style={{ marginTop: 7 }}
                        className="dashboard-datepicker"
                      >
                        <Input
                          type="text"
                          className="form-control"
                          defaultValue={restockState.filters.orderNumber}
                          onChange={(e) => {
                            clearTimeout(timer);

                            const newTimer = setTimeout(() => {
                              dispatch(
                                setRestockReportFilter({
                                  ...restockState.filters,
                                  orderNumber: e.target.value,
                                  currentPage: 1,
                                })
                              );
                            }, 500);

                            setTimer(newTimer);
                          }}
                          placeholder="order Number"
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md="">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <FormGroup
                        style={{ marginTop: 7 }}
                        className="dashboard-datepicker"
                      >
                        <Input
                          type="text"
                          className="form-control"
                          defaultValue={restockState.filters.sku}
                          onChange={(e) => {
                            clearTimeout(timer);

                            const newTimer = setTimeout(() => {
                              dispatch(
                                setRestockReportFilter({
                                  ...restockState.filters,
                                  sku: e.target.value,
                                  currentPage: 1,
                                })
                              );
                            }, 500);

                            setTimer(newTimer);
                          }}
                          placeholder="SKU"
                        />
                        <i className="fa fa-search"></i>
                      </FormGroup>
                    </Form>
                  </Col>

                  <Col md="" style={{ marginTop: 7 }}>
                    <Select
                      className="select2-filter"
                      styles={customStyles}
                      onChange={(e) => {
                        dispatch(
                          setRestockReportFilter({
                            ...restockState.filters,
                            restockStatus: e ? e.value : null,
                            currentPage: 1,
                          })
                        );
                        setRestockStatus(e ? e.value : null);
                      }}
                      isClearable={true}
                      placeholder="Restock Status"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={RestockStatus}
                    />
                  </Col>

                  <Col md="" style={{ marginTop: 7 }}>
                    <AsyncSelect
                      className="select2-filter"
                      isClearable={true}
                      cacheOptions
                      defaultOptions
                      placeholder="Restocked By"
                      loadOptions={userPromiseOption}
                      styles={{
                        ...customStyles,
                        menuPortal: (base) => ({ ...base, zIndex: 100 }),
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onClear={(e) => {}}
                      onChange={(e) => {
                        setRestockedByUser(e ? e.value : null);
                        dispatch(
                          setRestockReportFilter({
                            ...restockState.filters,
                            restockedBy: e ? e.value : null,
                            currentPage: 1,
                          })
                        );
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <h5 className="mb-2">Restock History </h5>
                <div>
                  <div className="Restock Report">
                    <CommonDataTable
                      headerColumns={tableColumns}
                      data={retrunRestockDatas}
                      noAction
                      selectableRows={false}
                      onSelectedRowsChange={handleRowSelected}
                      clearSelectedRows={toggleCleared}
                      paginationServer
                      paginationTotalRows={
                        restockState.restockOrdersDataTableRow
                      }
                      paginationRowsPerPageOptions={[10, 25, 50, 100]}
                      onChangeRowsPerPage={pagination}
                      onChangePage={pageChange}
                      expandableRows
                      expandableRowsComponent={ExpandedComponent}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default withReducer([{ order: restockReportReducer }])(RestockReport);
