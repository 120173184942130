import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Row, Popover,
    PopoverBody,
} from "reactstrap";
import { collabOrderFilters } from "../../manage-product/product/edit/content/settings/map";
import { mapCollabOrderResponseToTable, mapSearchAutoSuggestion } from "../../../utils/mapper";
import { getUsersApi } from "../../../services/manage-user/user";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../store/withReducer";

import { 
    getCollabOrders, 
    getOrderSettings, 
    setCollabOrderFilter, 
    setSettings, 
    exportCollabOrderFilteredData 
} from "../store/orderSlice";

import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import orderReducer from "../store";
import PreLoader from "../../../share-components/ui/preLoader";
import moment from "moment";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BackInStockTable from "../../backInStock/content/backInStockTable";
import { DateRangePicker } from 'rsuite';
import DatePicker from "../../../share-components/ui/DateRangePicker";
import AsyncSelect from "react-select/async";

export const schema = yup.object().shape({});


const { afterToday } = DateRangePicker;
const defaultValues = {
    file: '',
    deliveryPartner: ''
};

const CollabOrder = (props) => {
    const collabOrderState = useSelector(({ order }) => order.order);
    const dispatch = useDispatch();
    let history = useHistory();
    const [btnDisable, setBtnDisable] = useState(false);
    const [checkBoxData, setCheckBox] = useState({ packingSlip: [], pickingTicket: [] }, { idArray: [] });
    const [collabOrderData, setCollabOrders] = useState([]);
    const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
    const orderReduxData = useSelector(({ order }) => order.order.collabOrders);
    const submitTypes = ["submit", "update", "save"];
    const [differentPage, setDifferentPage] = useState(!submitTypes.includes(history.location.state?.from));
    const [loading, isSetLoading] = useState(history.location.state?.from !== "update" || orderReduxData.length < 1);
    const [isFilterable, setIsFilterable] = useState((history.location.state?.from !== "update" || orderReduxData.length < 1));
    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const actionToggle = () => setActionDropdownOpen(prevState => !prevState);
    const [actionBtnDropdownOpen, setActionBtnDropdownOpen] = useState(false);
    const actionToggleBtn = () => setActionBtnDropdownOpen(prevState => !prevState);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const [startDate, endDate] = dateRange;
    const [importDdpFile, setImportDdpFile] = useState(false);
    const [orderCheckBoxData, setOrderCheckBoxData] = useState({ idArray: [] });
    const [removed, setRemoved] = useState(false);
    const [isResetFilters, setIsResetFilters] = useState(false);
    const authData = useSelector(({ authReducer }) => authReducer);
    const userState = authData.data;
    const [verifiedByUser, setVerifiedByUser] = useState(null);
    const [timer, setTimer] = useState(null);


    const paymentStatusOptions = [
        { value: 'pending', label: 'Pending' },
        { value: 'paid', label: 'Paid' },
        { value: 'voided', label: 'Voided' },
    ]

    const VerificationStatusOptions = [
        { value: '0', label: 'Pending' },
        { value: '1', label: 'Approved' },
        { value: '2', label: 'Declined' },
    ]

    useEffect(() => {

        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);

        let start_date = null;
        let end_date = TodayDate;
        let verificationStatus = '';
        let financialStatus = '';
        let isParamavailable = false;

        if (params.has('end_date') && moment(params.get('end_date')).isValid()) {
            end_date = params.get('end_date');
            isParamavailable = true;
        }
        if (params.has('start_date') && moment(params.get('start_date')).isValid()) {
            start_date = params.get('start_date');
            setDateRange([start_date, end_date]);
            isParamavailable = true;
        }

        if (params.has('verificationStatus') && VerificationStatusOptions.find(option => option.value === params.get('verificationStatus'))) {
            verificationStatus = VerificationStatusOptions.find(option => option.value === params.get('verificationStatus'));
            isParamavailable = true;
        }

        if (params.has('financialStatus') && paymentStatusOptions.find(option => option.value === params.get('financialStatus'))) {
            financialStatus = paymentStatusOptions.find(option => option.value === params.get('financialStatus'));
            isParamavailable = true;
        }

        if (differentPage) {
            dispatch(setCollabOrderFilter(collabOrderFilters()));
        }

        if (isParamavailable) {
            dispatch(setCollabOrderFilter({
                ...collabOrderState.collabFilters,
                verificationStatus: verificationStatus,
                financialStatus: financialStatus,
                currentPage: 1,
            }));
        }

        if (collabOrderState.collabFilters.isResetFilters) {
            setIsResetFilters((value) => !value);
        }
    }, []);

    useEffect(() => {
        if (!differentPage && (isFilterable || ((isFilterable) && authData.defaultSeller?.value != collabOrderState.collabFilters.seller?.value))) {
            dispatch(setCollabOrderFilter({
                ...collabOrderState.collabFilters,
                seller: authData.defaultSeller,
                currentPage: 1,
            }));
        }
    }, [authData.defaultSeller])

    useEffect(() => {
        setCollabOrders(mapCollabOrderResponseToTable(orderReduxData, checkBoxData));
    }, [orderReduxData]);

    const dataMenu = [
        {
            type: 0,
            text: 'Collab Orders',
        }
    ];

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 50,
            border: 'none',
        })
    };

    useEffect(() => {
        setCollabOrders(mapCollabOrderResponseToTable(orderReduxData, checkBoxData));
        dispatch(getOrderSettings()).then(res => {
            dispatch(setSettings(res.payload))
        });
    }, [checkBoxData, dispatch]);

    const submitBtnSaveRef = useRef();

    const { handleSubmit, formState, reset, register, trigger, setValue, getValues } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const PopoverBasicItem = (props) => {
        const { id, btntext, Popoverbody } = props;
        const [popover, setPopover] = useState(false)
        const Toggle = () => setPopover(!popover);
        return (
            <>
                <p style={{ cursor: "pointer" }} className="example-popover" id={"Popover-" + id}>{btntext} <i className="fa fa-info-circle"></i></p>
                <Popover
                    placement="top"
                    isOpen={popover}
                    target={"Popover-" + id}
                    toggle={Toggle}
                    trigger="hover"
                >
                    <PopoverBody>
                        {Popoverbody}
                    </PopoverBody>
                </Popover>
            </>
        );
    };

    let tableColumns = [
        {
            name: 'Order ID',
            selector: (row) => {
                const btntext = (
                    <Link className={(row.isCanceled == 1) ? "text-danger" : "text-primary"} style={{ cursor: "pointer" }} to={`${process.env.PUBLIC_URL}/order/${row.id}/view`} target="_blank">{row.orderName}</Link>
                );
                return btntext;
            },
            sortable: true,
            center: false,
            width: '150px',
        },
        {
            name: 'Date',
            selector: row => row.orderDate,
            sortable: true,
            center: false,
        },
        {
            name: 'Verification Status',
            selector: row => {
                let _className = 'badge badge-warning';
                
                if (row.verificationStatus == 'Approved') {
                    _className = 'badge badge-success';

                }else if (row.verificationStatus == 'Declined') {
                    _className = 'badge badge-danger';
                    
                }

                return <span className={_className}>{row.verificationStatus}</span>
            },
            sortable: true,
            left: true,
        },
        {
            name: 'Verified By',
            selector: row => row.verifiedBy,
            sortable: true,
            left: true,
        },
        {
            name: 'Collab Order ID',
            selector: row => <p className="text-capitalize">{row.collabOrderName}</p>,
            sortable: true,
            center: true,
        },
        {
            name: 'Payment Status',
            selector: row => <p className="text-capitalize">{row.paymentStatus}</p>,
            sortable: true,
            center: true,
        },
        {
            name: 'Total (£)',
            selector: row => row.total,
            sortable: true,
            right: true,
            width: '180px',
        },
        {
            name: 'Action',
            selector: (row) => {
                if (row.verificationStatus == 'Approved' || row.verificationStatus == 'Declined') {
                    return <>
                        <a onClick={() => onView(row)} className="ml-1 btn btn-sm btn-info py-1 px-2" title="View"><i className="fa fa-eye"></i></a>
                    </>;
                }else {
                    return <>
                        <a onClick={() => onVerify(row)} className="ml-1 btn btn-sm btn-primary py-1 px-2" title="Verify"><i className="fa fa-check-circle"></i></a>
                    </>
                }
            },
            sortable: true,
            right: true,
        }
    ];


    useEffect(() => {

        if (isFilterable) {
            const collabFilters = collabOrderState.collabFilters;
            if (differentPage && JSON.stringify(collabFilters) != JSON.stringify(collabOrderFilters())) {
                setDifferentPage(false);
            } else {
                dispatch(getCollabOrders({
                    page: collabFilters.currentPage,
                    pageSize: collabFilters.pageSize,
                    start_date: moment(startDate).format('YYYY-MM-DD'),
                    end_date: moment(endDate).format('YYYY-MM-DD'),
                    approvalBy: collabOrderState.collabFilters.verifiedBy ? collabOrderState.collabFilters.verifiedBy : '',
                    approvalStatus: collabOrderState.collabFilters.verificationStatus ? collabOrderState.collabFilters.verificationStatus : '',
                    financialStatus: collabOrderState.collabFilters.financialStatus ? collabOrderState.collabFilters.financialStatus : '',
                    collabOrderName: collabOrderState.collabFilters.collabOrderName ? collabOrderState.collabFilters.collabOrderName : '',
                    sku: collabOrderState.collabFilters.sku ? collabOrderState.collabFilters.sku : '',
                    orderName: collabOrderState.collabFilters.orderName ? collabOrderState.collabFilters.orderName : ''
                })).then(res => {
                    isSetLoading(false);
                });

                setDifferentPage(false);
            }
        }

        setIsFilterable(true);

    }, [
        collabOrderState.collabFilters.currentPage, 
        collabOrderState.collabFilters.pageSize,
        collabOrderState.collabFilters.verifiedBy, 
        collabOrderState.collabFilters.verificationStatus, 
        collabOrderState.collabFilters.financialStatus, 
        collabOrderState.collabFilters.collabOrderName, 
        collabOrderState.collabFilters.sku,
        collabOrderState.collabFilters.orderName,
        isResetFilters,
        startDate, endDate, 
    ]);

    const onEdit = (row) => {

    }

    const filteredCollabOrderClick = () => {
        setBtnDisable(true);
        setImportDdpFile(false)
        const collabFilters = collabOrderState.collabFilters;

        const data = {
            page: collabFilters.currentPage,
            pageSize: collabFilters.pageSize,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            approvalBy: collabOrderState.collabFilters.verifiedBy ? collabOrderState.collabFilters.verifiedBy : '',
            approvalStatus: collabOrderState.collabFilters.verificationStatus ? collabOrderState.collabFilters.verificationStatus : '',
            financialStatus: collabOrderState.collabFilters.financialStatus ? collabOrderState.collabFilters.financialStatus : '',
            collabOrderName: collabOrderState.collabFilters.collabOrderName ? collabOrderState.collabFilters.collabOrderName : '',
            sku: collabOrderState.collabFilters.sku ? collabOrderState.collabFilters.sku : '',
            orderName: collabOrderState.collabFilters.orderName ? collabOrderState.collabFilters.orderName : '',
            pageType: 'report',
        }

        const outputFilename = `collab_orders_${(new Date().toJSON().slice(0, 10))}.csv`;

        dispatch(exportCollabOrderFilteredData({ ...data })).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false);
        });
    }

    //This is for filter using verified by
    const userPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue,
            };
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(getUsersApi(data)));
            });
        });

    const onView = (row) => {
        history.push('/order/collab-orders/' + row.id + '/view');
    }

    const onVerify = (row) => {
        history.push('/order/collab-orders/' + row.id + '/verify');
    }

    const pageChange = (e) => {
        dispatch(setCollabOrderFilter({ ...collabOrderState.collabFilters, currentPage: e }))
    }

    const onPageSizeChange = (e) => {
        dispatch(setCollabOrderFilter({ ...collabOrderState.collabFilters, currentPage: 1, pageSize: e }))
    }

    const toggleRowSelected = (id) => {
        // console.log(id)
    }

    return (
        <Fragment>
            {loading ? <>
                <PreLoader></PreLoader>
            </>
                : <>
                    <Breadcrumb title="Collab Orders" data={dataMenu} />
                    <Container fluid={true}>
                        <Card>
                            <Row>
                                <Col sm="4">
                                    <div className="dashboard-datepicker">
                                        <DatePicker
                                            dateRange={dateRange}
                                            dateRangeChange={(date) => {
                                                setDateRange(date)
                                            }}
                                            defaultValue={[TodayDate, sevenDays]}
                                            disabledDate={afterToday()}
                                        />
                                    </div>
                                </Col>

                                {/* {((userState.user.role.name !== "Seller") || (userState.user.is_primary_seller)) &&
                                    <Col sm="4">
                                        <div className='float-sm-right mt-2 mr-3'>
                                            <Button color="primary" className="btn btn-primary btn-square"
                                                onClick={() => { filteredCollabOrderClick() }}
                                            >Export Collab Orders</Button>
                                        </div>
                                    </Col>
                                } */}
                            </Row>
                        </Card>

                        <div className="product-grid">
                            <div className="feature-products">
                                <Row>
                                    <Col md="">
                                        <AsyncSelect
                                            className="select2-filter"
                                            isClearable={true}
                                            cacheOptions
                                            defaultOptions
                                            placeholder="Verified By"
                                            loadOptions={userPromiseOption}
                                            styles={{
                                                ...customStyles,
                                                menuPortal: (base) => ({ ...base, zIndex: 100 }),
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                            onClear={(e) => { }}
                                            onChange={(e) => {
                                                setVerifiedByUser(e ? e.value : null);
                                                dispatch(
                                                    setCollabOrderFilter({
                                                        ...collabOrderState.collabFilters,
                                                        verifiedBy: e ? e.value : null,
                                                        currentPage: 1,
                                                    })
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col md="">
                                        <FormGroup>
                                            <Select
                                                placeholder={<div>Verification Status</div>}
                                                className="select2-filter"
                                                isClearable
                                                cacheOptions
                                                styles={customStyles}
                                                options={VerificationStatusOptions}
                                                defaultValue={collabOrderState.collabFilters.verificationStatus}
                                                onChange={(e) => {
                                                    dispatch(setCollabOrderFilter({
                                                        ...collabOrderState.collabFilters,
                                                        verificationStatus: e ? e.value : null,
                                                        currentPage: 1,
                                                    }));
                                                }}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="">
                                        <FormGroup>
                                            <Select
                                                placeholder={<div>Payment Status</div>}
                                                className="select2-filter"
                                                isClearable
                                                cacheOptions
                                                styles={customStyles}
                                                options={paymentStatusOptions}
                                                defaultValue={collabOrderState.collabFilters.financialStatus}
                                                onChange={(e) => {
                                                    dispatch(setCollabOrderFilter({
                                                        ...collabOrderState.collabFilters,
                                                        financialStatus: e ? e.value : null,
                                                        currentPage: 1,
                                                    }));
                                                }}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="">
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                        }}>
                                            <FormGroup className="m-0">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={collabOrderState.collabFilters.orderName}
                                                    onChange={(e) => {
                                                        clearTimeout(timer);
                                                        const newTimer = setTimeout(() => {
                                                            dispatch(setCollabOrderFilter({
                                                                ...collabOrderState.collabFilters,
                                                                orderName: e.target.value,
                                                                currentPage: 1,
                                                            }));

                                                        }, 500);
                                                        setTimer(newTimer)
                                                    }}
                                                    placeholder="Search Order"
                                                />
                                                <i className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col md="">
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                        }}>
                                            <FormGroup className="m-0">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={collabOrderState.collabFilters.collabOrderName}
                                                    onChange={(e) => {
                                                        clearTimeout(timer);

                                                        const newTimer = setTimeout(() => {
                                                            // setName(e.target.value);
                                                            dispatch(setCollabOrderFilter({
                                                                ...collabOrderState.collabFilters,
                                                                collabOrderName: e.target.value,
                                                                currentPage: 1,
                                                            }));

                                                        }, 500);
                                                        setTimer(newTimer)
                                                    }}
                                                    placeholder="Search Collab Order"
                                                />
                                                <i className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col md="">
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                        }}>
                                            <FormGroup className="m-0">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={collabOrderState.collabFilters.sku}
                                                    onChange={(e) => {
                                                        clearTimeout(timer);

                                                        const newTimer = setTimeout(() => {
                                                            // setName(e.target.value);
                                                            dispatch(setCollabOrderFilter({
                                                                ...collabOrderState.collabFilters,
                                                                sku: e.target.value,
                                                                currentPage: 1,
                                                            }));

                                                        }, 500);
                                                        setTimer(newTimer)
                                                    }}
                                                    placeholder="Search SKU"
                                                />
                                                <i className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <Card>
                            <CardBody>
                                <Row>
                                    <div className="table-responsive product-table">
                                        <BackInStockTable
                                            paginationTotalRows={collabOrderState.collabOrderTableRow}
                                            idArray={orderCheckBoxData}
                                            remove={removed}
                                            tableColumns={tableColumns}
                                            backStockData={collabOrderData}
                                            backInRequestState={collabOrderState}
                                            onView={onView}
                                            onEdit={onEdit}
                                            pageChange={pageChange}
                                            onPageSizeChange={onPageSizeChange}
                                            toggleRowSelected={toggleRowSelected}
                                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                            paginationDefaultPage={collabOrderState.collabFilters.currentPage}
                                            paginationPerPage={collabOrderState.collabFilters.pageSize}
                                        />
                                    </div>
                                </Row>

                            </CardBody>
                        </Card>
                    </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{ order: orderReducer }])(CollabOrder);