import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, Form, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {closeActionDialog} from "../store/orderSlice";
import Select from "react-select";
import DropZone from "../../../share-components/upload/dropzone";
import Typography from "@material-ui/core/Typography";


export default function OrderActionForm(props) {

    const {
        dialog,
        size='lg',
        closeDialog,
        isUseSelector,
        title,
        handleSubmit,
        orderState,
        submitBtnSaveRef,
        handleFileInputChange,
        onSubmitForm,
        customStyles,
        setValue,
        trigger,
        uploadFile,
        saveBtnLoad
    } = props;


    // const addEditDialog = isUseSelector ? dialog : useSelector(dialog);
    const dispatch = useDispatch();
    // const [uploadFile, setUploadFile] = useState(null);

    const closeModalDialog = () => {
       dispatch(closeActionDialog());
    }

    return (
        <div className="flex flex-col modal-lg">
            <Modal size={size} {...orderState.actionDialog.props}  toggle={() => closeModalDialog()}>
                <ModalHeader toggle={() => {
                    closeModalDialog()
                }}>
                    {orderState.actionDialog.modalType===2? "Import DPD orders" :(orderState.actionDialog.modalType===3)?"Import Evri Oders": "Reassign delivery partner"}
                </ModalHeader>

                    <ModalBody>
                        {orderState.actionDialog.modalType!==0 && (orderState.actionDialog.modalType===2||orderState.actionDialog.modalType===3) &&
                            <Form onSubmit={handleSubmit(onSubmitForm)}>
                            <Row className="ml-2 mt-3">
                                <Col md={12}>
                                    <DropZone
                                        extension={".csv"}
                                        onChangeFile={handleFileInputChange}></DropZone>
                                    {/*<input type="file" name="file" onChange={handleFileInputChange}/>*/}
                                </Col>
                                {uploadFile ? (

                                    <Typography>File: {`${uploadFile[0].name}`}{' '}</Typography>

                                ) : (
                                    ''
                                )}
                            </Row>
                                <Row>
                                    <Col md={9}></Col>
                                    <Col md={3} className="mt-3">
                                        <button ref={submitBtnSaveRef} disabled={saveBtnLoad} className="btn btn-success btn-block">{saveBtnLoad ? ( // Show the spinner if loading state is true
                                            <i className="fa fa-spinner fa-spin fa-lg spinner mr-1" />
                                        ) : (
                                            'Import' // Show the regular text if loading state is false
                                        )}
                                        </button>
                                    </Col>
                                </Row>
                        </Form>}


                        {orderState.actionDialog.modalType!==0 && orderState.actionDialog.modalType===1 &&
                            <Form onSubmit={handleSubmit(onSubmitForm)}>
                                <Row>
                                    <Col md={6}>
                                        <Select
                                            isDisabled={true}
                                            value={orderState.currentDeliveryPartner}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            placeholder={<div>Delivery Partners </div>}
                                            className="select2-filter border"
                                            isClearable
                                            cacheOptions
                                            styles={customStyles}
                                            options={orderState.settings.deliveryPartners}
                                            onChange={(e) => {
                                                setValue('deliveryPartner', e);
                                                trigger('deliveryPartner');
                                            }}

                                        />
                                    </Col>

                                    <Col md={6}>
                                        <Select
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            placeholder={<div>Delivery Partners </div>}
                                            className="select2-filter border"
                                            isClearable
                                            cacheOptions
                                            styles={customStyles}
                                            options={orderState.currentDeliveryPartners}
                                            onChange={(e) => {
                                                setValue('deliveryPartner', e);
                                                trigger('deliveryPartner');
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={9}></Col>
                                    <Col md={3} className="mt-3" style={{float:"right"}}>
                                        <button ref={submitBtnSaveRef} disabled={saveBtnLoad} onClick={() => {
                                            // setActionBtn(1)
                                        }} className="btn btn-success btn-block">{saveBtnLoad ? ( // Show the spinner if loading state is true
                                            <i className="fa fa-spinner fa-spin fa-lg spinner mr-1" />
                                        ) : (
                                            'Submit' // Show the regular text if loading state is false
                                        )}
                                        </button>
                                    </Col>
                                </Row>

                            </Form>}

                    </ModalBody>
            </Modal>
        </div>
    )
}